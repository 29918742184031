import { useMemo, useRef, useState } from "react";
import * as PR from '../../prime-modules/index';
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { changeUserPassword } from "../../services/api";
import { globalConfig } from "../../constants";
import { authActions } from "../../store/auth";
import { cartFilterActions } from "../../store/cart";
import { removeWhiteSpacesInPassword } from "../../utils/reuse";

const ChangePassword = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef();
    const userData = useSelector(state => state.auth.sessionData);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return {sessionid: userData.sessionId, afid: affiliateTrackingData};
    }, [userData.sessionId, affiliateTrackingData]);

    const [disableBtn, setDisableBtn] = useState(false);
    const initialValues = {
        password: '',
        newPassword: '',
        confirmPassword: ''
    };

    const validationSchema = () => {
        return Yup.object().shape({
            password: Yup.string().trim()
                .required('validation.currentPassword'),
            newPassword: Yup.string()
                .required('validation.password')
                .min(8, 'validation.inValidPasswordErr')
                .max(20, 'validation.inValidPasswordErr')
                .matches(/\d/, 'validation.inValidPasswordErr')
                .matches(/[A-Za-z]/, 'validation.inValidPasswordErr')
                .trim('validation.inValidPasswordErr')
                .strict(true),
            confirmPassword: Yup.string()
                .required('validation.confirmPassword')
                .when("newPassword", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("newPassword")],
                        'validation.notMatchedErr'
                    )
                })
        });
    };

    const handleSubmit = values => {
        setDisableBtn(true);
        const requestObj = {
            userId: userData.userId ?? 0,
            password: values.password,
            newPassword: values.newPassword
        }
        changeUserPassword(requestObj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                toast.current.show({ life:globalConfig.toastDisplayTime, severity: 'success', summary:t('toastSummary.success'), detail: t('toast.passwordSuccessUpdate') })
                setTimeout(() => {
                    dispatch(authActions.onLogout());
                    dispatch(cartFilterActions.removeRandomIdInPayment());
                }, [2000])
            } else {
                const error = response.error;
                const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                toast.current.show({ life:globalConfig.toastDisplayTime, severity: error.severity, summary:t('toastSummary.error'), detail: t(`errorMsg.${errorMsg}`) })
            }
            setDisableBtn(false);
        })
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    return ( 
        <div className="esim-box">
            <PR.Toast ref={toast} position="top-right" />
        <h2>{t("changePassword.title")}</h2>
        <form onSubmit={formik.handleSubmit} autoComplete={"false"}>
          <div className="grid mb-3">
            <div className="col-12 lg:col-6 md:col-6">
              <div className="flex flex-column gap-2">
                  <label htmlFor="password">{t("changePassword.currentPassword")}</label>
                  <span className="p-input-icon-right">
                      <PR.Password  feedback={false} id='password' name='password' placeholder={t('placeholder.currentPassword')} className="w-12" autoComplete="off" value={formik.values.password} onChange={e => removeWhiteSpacesInPassword(e, formik, "password")} onBlur={formik.handleBlur} toggleMask />
                  </span>
                  {formik.errors.password && formik.touched.password && <div className='error-message'>{t(formik.errors.password)}</div>}
              </div>
            </div>
          </div>
          <div className="grid mb-3">
            <div className="col-12 lg:col-6 md:col-6">
              <div className="flex flex-column gap-2">
                  <label htmlFor="newPassword">{t("changePassword.newPassword")}</label>
                  <span className="p-input-icon-right">
                      <PR.Password feedback={false} id='newPassword' name='newPassword' placeholder={t('placeholder.newPassword')} className="w-12" autoComplete="off" value={formik.values.newPassword} onChange={e => removeWhiteSpacesInPassword(e, formik, "newPassword")} onBlur={formik.handleBlur} toggleMask maxLength={20} />
                  </span>
                  {formik.errors.newPassword && formik.touched.newPassword && <div className='error-message'>{t(formik.errors.newPassword)}</div>}
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12 lg:col-6 md:col-6">
              <div className="flex flex-column gap-2">
                  <label htmlFor="confirmPassword">{t("changePassword.confirmPassword")}</label>
                  <span className="p-input-icon-right">
                      <PR.Password feedback={false} id='confirmPassword' name='confirmPassword' placeholder={t('placeholder.confirmPassword')} className="w-12" autoComplete="off" value={formik.values.confirmPassword} onChange={e => removeWhiteSpacesInPassword(e, formik, "confirmPassword")} onBlur={formik.handleBlur} toggleMask maxLength={20} />
                  </span>
                  {formik.errors.confirmPassword && formik.touched.confirmPassword && <div className='error-message'>{t(formik.errors.confirmPassword)}</div>}
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12">
              <PR.Button label={t("changePassword.savePassword")} className="update-button p-button-rounded" disabled={!formik.isValid || disableBtn} />
            </div>
          </div>
        </form>
    </div>
     );
}
 
export default ChangePassword;