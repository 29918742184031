import React from "react";
import "../privacy-policy/PrivacyPolicy.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import SubHeader from "../../components/SubHeader";
import SupportMailsSection from "../../components/SupportMailsSection";
import { globalConfig } from "../../constants";

const PrivacyPolicy = () => {
    const { t } = useTranslation();

  return (
     <HelmetProvider> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>{globalConfig.domainName} | {t("privacyPolicy.title")}</title>
      </Helmet>

      {/* Header Section */}
      <SubHeader header={t("privacyPolicy.title")} subText={t("privacyPolicy.description")} />

      <section className="about-blocks-section tc-block">
        <div className="privacy-policy-section terms-and-conditions-section">
            <p>{globalConfig.domainName} understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of everyone who visits this website, <a href={globalConfig.domainUrl}  rel="canonical">{globalConfig.domainUrl}</a> (“Our Site”) and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law. These Privacy Policies are in the English language only.</p>
            <p>Please read this Privacy Policy carefully and ensure that you understand it. Your acceptance of this Privacy Policy is requested when you visit our Site.</p>
            <ol className="sub-number-ol"> 
                <li>
                    <span className="heading">Information About Us</span>
                    <p>Our Site is owned and operated by Sendso Ltd., a limited company registered in England under company number <b>13154302</b> whose registered office is at <b>115c, Milton Road, CAMBRIDGE CB4 1XE, ENGLAND</b>.</p>
                </li>
                <li>
                    <span className="heading">What does this Policy cover?</span>
                    <p>This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.</p>
                </li>
                <li>
                    <span className="heading">What is personal data?</span>
                    <p>Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) and the Data Protection Act 2018 or any successor legislation (collectively, “the Data Protection Legislation”) as 'any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier'.</p>
                    <p>Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.</p>
                </li>
                <li>
                    <span className="heading">What are my rights?</span>
                    <p>Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:</p>
                    <ol className="sub-ol">
                        <li>The right to be informed about our collection and use of your personal data. This Privacy Policy should tell you everything you need to know, but you can always contact us to find out more or to ask any questions using the details in Part 12.</li>
                        <li>The right to access the personal data we hold about you. Part 11 will tell you how to do this.</li>
                        <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us using the details in Part 12 to find out more.</li>
                        <li>The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we hold. Please contact us using the details in Part 12 to find out more.</li>
                        <li>The right to restrict (i.e. prevent) the processing of your personal data.</li>
                        <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
                        <li>The right to withdraw consent. This means that, if we are relying on your consent as the legal basis for using your personal data, you are free to withdraw that consent at any time.</li>
                        <li>The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.</li>
                        <li>Rights relating to automated decision-making and profiling. We do not use your personal data in this way.</li>
                    </ol>
                    <p>For more information about our use of your personal data or exercising your rights as outlined above, please contact our using the details provided in Part 12.</p>
                    <p>It is important that your personal data is kept accurate and up-to-date. If any of the personal data we hold about you changes, please keep us informed as long as we have that data.</p>
                    <p>Further information about your rights can also be obtained from the Information Commissioner's Office or your local Citizens Advice Bureau.</p>
                    <p>If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner's Office. We would welcome the opportunity to resolve your concerns ourselves, however, so please contact us first, using the details in Part 12.</p>
                </li>
                <li>
                    <span className="heading">What data do you collect and how?</span>
                    <p>Depending upon your use of Our Site, we may collect and hold some or all of the personal data set out in the table below, using the methods also set out in the table. We do not collect any 'special category' or 'sensitive' personal data or personal data relating to children and/or data relating to criminal convictions and/or offenses. We may hold your passport details</p>
                    <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
                    <ol className="sub-ol">
                        <li><b>Identity Data</b> includes first name, last name, username or similar identifier, marital status, title, date of birth, gender and passport details.</li>
                        <li><b>Contact Data</b> includes billing address, delivery address, email address and telephone numbers.</li>
                        <li><b>Financial Data</b> includes bank account and payment card details.</li>
                        <li><b>Transaction Data</b> includes details about payments to and from you and other details of products and services you have purchased from us.</li>
                        <li><b>Technical Data</b> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
                        <li><b>Profile Data</b> includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</li>
                        <li><b>Usage Data</b> includes information about how you use our website, products and services.</li>
                        <li><b>Marketing and Communications Data</b> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
                    </ol>
                </li>
                <li>
                    <span className="heading">How do you use my personal data?</span>
                    <p>Under the Data Protection Legislation, we must always have a lawful basis for using personal data. The following table describes how we will use your personal data, and our lawful bases for doing so:</p>
                    <div className='table-responsive'>
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th>What we do</th>
                                    <th className="min-width">What data we use</th>
                                    <th>Our lawful basis</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>To register you as a new customer</td>
                                    <td>
                                        <ol>
                                            <li>Identity</li>
                                            <li>Contact</li>
                                        </ol>
                                    </td>
                                    <td>Performance of a contract with you</td>
                                </tr>
                                <tr>
                                    <td><p>To process and deliver your order including:</p>
                                        <ol>
                                            <li>Manage payments, fees and charges</li>
                                            <li>Collect and recover money owed to us</li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol>
                                            <li>Identity</li>
                                            <li>Contact</li>
                                            <li>Financial</li>
                                            <li>Transaction</li>
                                            <li>Marketing and Communications</li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol>
                                            <li>Performance of a contract with you</li>
                                            <li>Necessary for our legitimate interests (to recover debts due to us)</li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p>To manage our relationship with you which will include:</p>
                                        <ol>
                                            <li>Notifying you about changes to our terms or privacy policy</li>
                                            <li>Asking you to leave a review or take a survey</li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol>
                                            <li>Identity</li>
                                            <li>Contact</li>
                                            <li>Profile</li>
                                            <li>Marketing and Communications</li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol>
                                            <li>Performance of a contract with you</li>
                                            <li>Necessary to comply with a legal obligation</li>
                                            <li>Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr>
                                    <td>To enable you to partake in a prize draw, competition or complete a survey</td>
                                    <td>
                                        <ol>
                                            <li>Identity</li>
                                            <li>Contact</li>
                                            <li>Profile</li>
                                            <li>Usage</li>
                                            <li>Marketing and Communications</li>
                                        </ol>
                                    </td>
                                    <td>
                                    <ol>
                                        <li>Performance of a contract with you</li>
                                        <li>Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)</li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr>
                                    <td>To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                                    <td>
                                        <ol>
                                            <li>Identity</li>
                                            <li>Contact</li>
                                            <li>Technical</li>
                                        </ol>
                                    </td>
                                    <td>
                                        <ol>
                                            <li>Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</li>
                                            <li>Necessary to comply with a legal obligation</li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr>
                                    <td>To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</td>
                                    <td>
                                        <ol>
                                            <li>Identity</li>
                                            <li>Contact</li>
                                            <li>Profile</li>
                                            <li>Usage</li>
                                            <li>Marketing and Communications</li>
                                            <li>Technical</li>
                                        </ol>
                                    </td>
                                    <td>
                                        Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)
                                    </td>
                                </tr>
                                <tr>
                                    <td>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</td>
                                    <td>
                                        <ol>
                                            <li>Technical</li>
                                            <li>Usage</li>
                                        </ol>
                                    </td>
                                    <td>
                                    Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)
                                    </td>
                                </tr>
                                <tr>
                                    <td>To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
                                    <td>
                                        <ol>
                                            <li>Identity</li>
                                            <li>Contact</li>
                                            <li>Technical</li>
                                            <li>Usage</li>
                                            <li>Profile</li>
                                            <li>Marketing and Communications</li>
                                        </ol>
                                    </td>
                                    <td>Necessary for our legitimate interests (to develop our products/services and grow our business)</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>With your permission and/or where permitted by law, we may also use your personal data for marketing purposes, which may include contacting you by email and/or telephone and/or text message and/or post with information, news, and offers on our products and services. You will not be sent any unlawful marketing or spam. We will always work to fully protect your rights and comply with our obligations under the Data Protection Legislation and the Privacy and Electronic Communications (EC Directive) Regulations 2003, and you will always have the opportunity to opt-out. We will always obtain your express opt-in consent before sharing your personal data with third parties for marketing purposes and you will be able to opt-out at any time.</p>
                    <p>We will only use your personal data for the purpose(s) for which it was originally collected unless we reasonably believe that another purpose is compatible with that or those original purpose(s) and need to use your personal data for that purpose. If we do use your personal data in this way and you wish us to explain how the new purpose is compatible with the original, please contact us using the details in Part 12.</p>
                    <p>If we need to use your personal data for a purpose that is unrelated to, or incompatible with, the purpose(s) for which it was originally collected, we will inform you and explain the legal basis which allows us to do so.</p>
                    <p>In some circumstances, where permitted or required by law, we may process your personal data without your knowledge or consent. This will only be done within the bounds of the Data Protection Legislation and your legal rights.</p>
                    </li>
                    <li>
                        <span className="heading">How long will you keep my personal data?</span>
                        <p>We will not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected. Your personal data will therefore be kept for the following periods (or, where there is no fixed period, the following factors will be used to determine how long it is kept):</p>
                        <div className='table-responsive'>
                            <table className="content-table">
                                <thead>
                                    <tr>
                                        <th>Type of data</th>
                                        <th>How long we keep it</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>Contact Data</b> includes billing address, delivery address, email address and telephone numbers.</td>
                                        <td>Whilst you are a customer and for three years after you are a customer</td>
                                    </tr>
                                    <tr>
                                        <td><b>Financial Data</b> includes an identifier number issued by payment processo</td>
                                        <td>6 years.</td>
                                    </tr>
                                    <tr>
                                        <td><b>Identity Data</b> includes first name, last name, username or similar identifier, and title.</td>
                                        <td>Whilst you are a customer and for three years after you are a customer</td>
                                    </tr>
                                    <tr>
                                        <td><b>Transaction Data</b> includes details about payments to and from you and other details of products and services you have purchased from us.</td>
                                        <td>Whilst you are a customer and for six years after you are a customer.</td>
                                    </tr>
                                    <tr>
                                        <td><b>Technical Data</b> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</td>
                                        <td>2 years</td>
                                    </tr>
                                    <tr>
                                        <td><b>Profile Data</b> includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</td>
                                        <td>Whilst you are a customer and for six years after you are a customer</td>
                                    </tr>
                                    <tr>
                                        <td><b>Marketing and Communications Data</b> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</td>
                                        <td>Whilst you are a customer and for three years after you are a customer<br/> For three years after you make an enquiry with us or register to receive marketing communications</td>
                                    </tr>
                                </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <span className="heading">How and where do you store or transfer my personal data?</span>
                    <p>Your data is stored in Germany. If any personal data is transferred outside of the EEA, we will take suitable steps in order to ensure that your personal data is treated just as safely and securely as it would be within the UK and under the Data Protection Legislation, as explained below.</p>
                    <p>We may store or transfer some or all of your personal data in countries that are not part of the EEA. These are known as “third countries” and may not have data protection laws that are as strong as those in the UK and/or the EEA. This means that we will take additional steps in order to ensure that your personal data is treated just as safely and securely as it would be within the UK and under the Data Protection Legislation as follows:</p>
                    <p>We use specific contracts with external third parties that are approved by the European Commission for the transfer of personal data to third countries. These contracts require the same levels of personal data protection that would apply under the Data Protection Legislation. More information is available from the <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/eu-us-data-transfers_en"  target='_blank' rel="noreferrer">European Commission.</a></p>
                    <p>Where we transfer your data to a third party based in the US, the data may be protected if they are part of the EU-US Privacy Shield. This requires that third party to provide data protection to standards similar to those in Europe. More information is available from the <a href="https://commission.europa.eu/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"  target='_blank' rel="noreferrer">European Commission.</a></p>

                    <p>The security of your personal data is essential to us, and to protect your data, we take a number of important measures, including the following:</p>
                    <p className="custom-p">
                    <span>limiting access to your personal data to those employees, agents, contractors, and other third parties with a legitimate need to know and ensuring that they are subject to duties of confidentiality;</span>
                    <span>procedures for dealing with data breaches (the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, your personal data) including notifying you and/or the Information Commissioner's Office where we are legally required to do so.</span>
                    </p>
                </li>
                <li>
                    <span className="heading">Do you share my personal data?</span>
                    <p>We will not share any of your personal data with any third parties for any purposes, subject to the following exceptions:</p>
                    <p>If we sell, transfer, or merge parts of our business or assets, your personal data may be transferred to a third party. Any new owner of our business may continue to use your personal data in the same way(s) that we have used it, as specified in this Privacy Policy.</p>
                    <p>In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</p>
                    <p>We may sometimes contract with the following third parties to supply certain products and/or services.</p>
                    <div className='table-responsive'>
                            <table className="content-table">
                                <thead>
                                    <tr>
                                        <th>Recipient</th>
                                        <th>Activity Carried Out</th>
                                        <th>Sector</th>
                                        <th>Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Stripe</td>
                                        <td>Payments</td>
                                        <td>Finance</td>
                                        <td>UK</td>
                                    </tr>
                                    <tr>
                                        <td>Paypal</td>
                                        <td>Payments</td>
                                        <td>Finance</td>
                                        <td>UK</td>
                                    </tr>
                                    <tr>
                                        <td>Apple Pay</td>
                                        <td>Payments</td>
                                        <td>Finance</td>
                                        <td>UK</td>
                                    </tr>
                                    <tr>
                                        <td>Google Pay</td>
                                        <td>Payments</td>
                                        <td>Finance</td>
                                        <td>UK</td>
                                    </tr>
                                    <tr>
                                        <td>Bluehouse Technology</td>
                                        <td>Site maintenance</td>
                                        <td>IT</td>
                                        <td>UK</td>
                                    </tr>
                                    <tr>
                                        <td>Contabo</td>
                                        <td>Storage</td>
                                        <td>IT</td>
                                        <td>Germany</td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>
                    <p>If any of your personal data is shared with a third party, as described above, we will take steps to ensure that your personal data is handled safely, securely, and in accordance with your rights, our obligations, and the third party's obligations under the law, as described above in Part 8.</p>
                    <p>If we sell, transfer, or merge parts of our business or assets, your personal data may be transferred to a third party. Any new owner of our business may continue to use your personal data in the same way(s) that we have used it, as specified in this Privacy Policy.</p>
                    <p>In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</p>
                </li>
                <li>
                    <span className="heading">Can I withhold information?</span>
                    <p>You may access certain areas of Our Site without providing any personal data at all. However, to use all features and functions available on Our Site you may be required to submit or allow for the collection of certain data.</p>
                </li>
                <li>
                    <span className="heading">How can I access my personal data?</span>
                    <p>If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a “subject access request”.</p>
                    <p>All subject access requests should be made in writing and sent to the email or postal addresses shown in Part 12.</p>
                    <p>There is not normally any charge for a subject access request. If your request is 'manifestly unfounded or excessive' (for example, if you make repetitive requests) a fee may be charged to cover our administrative costs in responding.</p>
                    <p>We will respond to your subject access request within one month and, in any case, not more than one month of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request. You will be kept fully informed of our progress.</p>
                </li>
                <li>
                    <span className="heading">How do I contact you?</span>
                    <p>To contact us about anything to do with your personal data and data protection, including to make a subject access request, please send us an email at <a href={`mailto:${globalConfig.supportEmail}`} target='_top' rel="noreferrer">{globalConfig.supportEmail}</a></p>
                    <p>Post: <b>115c Milton Road, Cambridge CB4 1XE, England.</b></p>
                </li>
                <li>
                    <span className="heading">Changes to this Privacy Policy</span>
                    <p>We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.</p>
                    <p>Any changes will be immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Policy on your first use of Our Site following the alterations. We recommend that you check this page regularly to keep up-to-date.</p>
                </li>
            </ol>
        </div>
      </section>

      <section className="inner-header-section aboutus-header-section support-section">
        <SupportMailsSection/>
      </section>
    </HelmetProvider>
  );
};

export default PrivacyPolicy;