import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as PR from "../prime-modules/index";
import { useDispatch, useSelector } from "react-redux";
import { globalConfig, responsiveOptions } from '../constants';
import { getCatalogsList, getCountriesList } from '../services/api';
import { countryActions } from '../store/country';
import { cartFilterActions } from "../store/cart";
import { affiliateTrackingInfoActions } from "../store/affiliateTracking";
import { useLocation, useNavigate } from 'react-router-dom';
import { renderPrice, renderSpeed, setDataAmount, setNumberPrecision } from '../utils/reuse';
import { plausible } from '../utils/plausibleTracker';
import { useTranslation } from 'react-i18next';
import MakePayment from './payment/MakePayment';
import { BundleListLoading } from './skeletonLoading/BundleListLoading';
import { esimIccidActions } from '../store/esim';
import RoamingCountries from '../shared/components/RoamingCountries';

const BundlesList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getCartData = useSelector((state) => state.cart.catalogCart);
  const getCountry = useSelector((state) => state.country.country);
  const getIPCountry = useSelector((state) => state.country.IPCountry);
  const iccid = useSelector(state => state.esim.esimIccid);
  const isAuth = useSelector(state => state.auth.isLoggedIn);
  const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [countryVal, setCountryVal] = useState(true);
  const [country, setCountry] = useState("");
  const [countryData, setCountryData] = useState('');
  const [countries, setCountries] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogData, setCatalogData] = useState({});
  const [bundleInfoVisible, setVundleInfoVisible] = useState(false);
  const [direction, setDirection] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const filter = '';
  const filterBy = '';
  const page = '';
  const limit = '';
  const queryParams = new URLSearchParams(useLocation().search);
  const afid = queryParams.get('afid');
  const [chackoutFormState, setCheckoutFormState] = useState(false)

  const getCatalogs = useCallback(() => {
    if (countryVal) {
      const getCatalogList = (response) => {

        if (response.result === "SUCCESS") {
          const catalogList = response.data && response.data.bundles ? response.data.bundles : [];
          if (catalogList.length > 0) {
            let sortedCatalogs = catalogList.sort((a, b) => a.price - b.price)
            setCatalogs(sortedCatalogs);
          } else {
            toast.current.show({ life: globalConfig.toastDisplayTime, severity: 'info', summary: t("toastSummary.info"), detail: t("home.esim.plansError") });
          }
        } else {
          const error = response.error;
          const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
          toast.current.show({ life: globalConfig.toastDisplayTime, severity: error.severity, summary: t("toastSummary.error"), detail: (`errorMsg.${errorMsg}`) })
        }
        setLoading(false);
      }
      if (country && country !== '') {
        setLoading(true);
        setCatalogs([]);
        const catalogDirection = (direction === 1) ? 'asc' : 'desc';
        const catalogCountry = encodeURIComponent(country);
        const getIccid = (isAuth && iccid) ? '&iccid=' + iccid : '';
        const queryParameters = 'countries=' + catalogCountry + '&page=' + page + '&limit=' + limit + '&direction=' + catalogDirection + '&orderBy=' + orderBy + '&filter=' + filter + '&filterBy=' + filterBy + getIccid;
        getCatalogsList(queryParameters, headers, dispatch, getCatalogList)
      }
    }
  }, [countryVal, country, t, direction, isAuth, iccid, orderBy, headers, dispatch])

  const setDataDuration = (duration) => {
    return (duration && (duration) + t("home.esim.days"));
  };

  const setDataSpeed = (speed) => {
    return (speed && (speed.join('/')));
  };

  const getNetworks = (countries) => {
    let networks = [];
    if (countries && countries.length > 0) {
      const networksList = countries[0]['networks'];
      if (networksList && networksList.length > 0) {
        networks = networksList;
      }
    }
    return networks;
  }

  const setNetworks = (countries) => {
    const networks = getNetworks(countries);

    return (networks &&
      ((networks.length === 0)
        ? <span>N/A</span>
        : <span className="networkData">
          {networks.map((n, index) => (
            <span key={index} className="catalogNetworks">
              {n.name} {n.speeds && '(' + (setDataSpeed(n.speeds)) + ')'}
            </span>
          ))}
        </span>
      )
    )
  }

  const clearStoreContent = useCallback(() => {
    dispatch(cartFilterActions.deleteCatalogCart());
    dispatch(cartFilterActions.setCatalogCartAmount(0));
  }, [dispatch])

  const trackCountry = useCallback((countryName) => {
    plausible.trackEvent(
      'country', { props: { country: countryName } }
    );
  }, [])

  const getCountryData = useCallback(() => {
    if (countryData === '') {
      setCountryData(null);
      const getCountryData = (response) => {
        if (response.result === "SUCCESS") {
          const countriesData = response.data ? response.data : [];
          if (countriesData.length > 0) {
            const countriesList = countriesData.map(c =>
              ({ name: c.name.split('(')[0].trim(), region: c.region, iso: c.iso }))
            setCountries(countriesList);

            const findCountry = countriesList.find(c => c.iso === response.ipcountry);
            let defaultCountry = findCountry && findCountry.iso ? findCountry.iso : countriesList[0]['iso'];
            if (getIPCountry !== response.ipcountry) {
              clearStoreContent();
              dispatch(countryActions.setIpCountry(response.ipcountry))
            } else if (getCountry && getCountry.iso) {
              defaultCountry = countriesList.find(c => c.iso === getCountry.iso) ? getCountry.iso : countriesList[0]['iso'];
            }
            setCountry(defaultCountry)
            const getFilteredCountry = countriesList.find(val => val.iso === defaultCountry)
            trackCountry(getFilteredCountry.name);
            dispatch(countryActions.setCountry(getFilteredCountry));

            setDirection(1);
            setOrderBy('price');
            setCountryData(defaultCountry);
          } else {
            toast.current.show({ life: globalConfig.toastDisplayTime, severity: 'info', summary: t("toastSummary.info"), detail: t("home.countriesError") });
          }
        } else {
          const error = response.error;
          const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
          toast.current.show({ life: globalConfig.toastDisplayTime, severity: error.severity, summary: "error", detail: t(`errorMsg.${errorMsg}`) })
        }
      };
      const url = 'countries';
      getCountriesList(url, headers, dispatch, getCountryData)
    }
  }, [countryData, headers, dispatch, getIPCountry, getCountry, trackCountry, clearStoreContent, t]);

  useEffect(() => {
    if (afid) {
      navigate("/")
      dispatch(affiliateTrackingInfoActions.setAffiliateTrackingInfo(afid));
    }
  }, [afid, dispatch, navigate])

  useEffect(() => {
    getCountryData();
  }, [getCountryData]);

  useEffect(() => {
    getCatalogs();
  }, [getCatalogs]);

  const catalogPurchase = (catalogData) => {
    const catalogName = catalogData.name;
    const catalogPrice = setNumberPrecision(catalogData.price);

    const getCartObj = { ...getCartData };

    if (getCartObj !== catalogName) {
      const setCartData = {
        name: catalogName,
        description: catalogData.description,
        quantity: 1,
        price: +catalogPrice,
        speed: catalogData.speed,
        duration: catalogData.duration,
        dataAmount: catalogData.dataAmount,
        dataAmountForDisplay: catalogData.dataAmountForDisplay
      };
      dispatch(cartFilterActions.setCatalogCart(setCartData));
      dispatch(cartFilterActions.setCatalogCartAmount(+catalogPrice));
    }

    const catalogCountry = catalogData.countries[0]['country']['iso'];
    const getFilteredCountry = countries.find(x => x.iso === catalogCountry);
    dispatch(countryActions.setCountry(getFilteredCountry));
    dispatch(esimIccidActions.deleteEsim());
    setCountry(catalogCountry);
    setCheckoutFormState(true);
  }

  const displayCatalogDetails = (catalogData) => {
    setCatalogData(catalogData);
    setVundleInfoVisible(true);
  }
  const productTemplate = (rowData) => {
    return (
      <div className="border-1 surface-border plan-box">
        <h3>{rowData.description}</h3>
        <div className='flex align-items-center justify-content-between mb-5'>
          <h2>{renderPrice(rowData.price)}</h2>
          <i className="pi pi-eye" title={t("home.esim.bundleDetails")} onClick={() => { displayCatalogDetails(rowData); }}></i>
        </div>
        {rowData?.dataAmount &&
          <p className='pi pi-check'><span className='font-semibold'>{setDataAmount(rowData, t)}</span></p>
        }
        {rowData.speed &&
          <p className='pi pi-check'><span>{renderSpeed(rowData.speed)}</span></p>
        }
        <p className='pi pi-check'><span>{t("home.stableConnection")}</span></p>
        <p className='pi pi-check'><span>{t("home.easyConfiguration")}</span></p>
        <PR.Button label={t("home.buyBtn")} className="goto-plans-button p-button-rounded" onClick={() => catalogPurchase(rowData)} />
      </div>
    );
  };

  const onCountryChange = (e) => {
    if(e.value){
      setCountry(e.value);
      setCountryVal(false);
    }
  };

  const onCountryHide = (e) => {
    if (getCountry.iso !== country) {
      clearStoreContent();
      const getFilteredCountry = countries.find(x => x.iso === country);
      dispatch(countryActions.setCountry(getFilteredCountry));
      trackCountry(getFilteredCountry.name);
      setCountryVal(true);
    }
  }

  const onHide = () => {
    setVundleInfoVisible(false)
    setCatalogData({});
  }

  return (
    <>
      <PR.Toast ref={toast} position='top-right' />
      <div className='country-dropdown'>
        <span className='label-span'>{t("home.iWant")}</span>
        <PR.Dropdown
          filter
          value={country}
          options={countries}
          onChange={(e) => onCountryChange(e)}
          onHide={(e) => onCountryHide(e)}
          resetFilterOnHide
          optionLabel="name"
          optionValue="iso"
          placeholder={t("placeholder.selectCountry")}
        />
      </div>
      <PR.Dialog header={t("home.esim.bundleDetails")} className='bundle-dialog' visible={bundleInfoVisible} blockScroll={true} draggable={false} breakpoints={{ '960px': '85vw' }} style={{ width: '38vw' }} onHide={onHide}>
        <div className="data-item">
          <div className="data-item-header">
            <div className="grid grid-nogutter mt-2 align-items-center">
              <div className="col-12 md:col-9 lg:col-9">
                <h3>
                  <span className="vertical-align-super">{catalogData.description}</span>
                </h3>
              </div>
              <div className="col-12 md:col-3 lg:col-3 text-right">
                {catalogData.price && (
                  <h3 className='color-h3'>{renderPrice(catalogData.price)}</h3>
                )}
              </div>
            </div>
          </div>
          <div className="data-item-content">

            <table className="w-12">
              <tbody>
                <tr>
                  <td>{t("home.esim.data")}</td>
                  <td className="text-right">{setDataAmount(catalogData, t)}</td>
                </tr>
                <tr>
                  <td>{t("home.esim.duration")}</td>
                  <td className="text-right">{setDataDuration(catalogData.duration)}</td>
                </tr>
                {catalogData.speed && (
                  <tr>
                    <td>{t("home.esim.speed")}</td>
                    <td className="text-right">{setDataSpeed(catalogData.speed)}</td>
                  </tr>
                )}
                <tr>
                  <td>{t("home.esim.roaming")}</td>
                  <td className="text-right">{<RoamingCountries roaming={catalogData.roamingEnabled} />}</td>
                </tr>
                <tr className={(getNetworks(catalogData.countries).length > 0) ? 'network-tr' : ''}>
                  <td>{t("home.esim.networks")}</td>
                  <td>{setNetworks(catalogData.countries)}</td>
                </tr>
                <tr className='last-row'>
                  <td className="text-left"><PR.Button label={t("home.esim.buyNow")} className="buynow-btn" onClick={() => { catalogPurchase(catalogData) }} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </PR.Dialog>
      <PR.Dialog visible={chackoutFormState} className='forms-dialog payment-dialog' blockScroll={true} draggable={false} style={{ width: '75%' }} onHide={() => setCheckoutFormState(false)}>
        <MakePayment setCheckoutFormState={setCheckoutFormState}/>
      </PR.Dialog>
      {
        loading
          ? BundleListLoading()
          : <PR.Carousel
            value={catalogs}
            numVisible={4}
            numScroll={1}
            responsiveOptions={responsiveOptions}
            itemTemplate={productTemplate}
          />
      }
    </>
  )
}

export default BundlesList