import React, { useState, useRef, useMemo, useEffect } from 'react';
import * as PR from "../../prime-modules/index";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../../services/api';
import { fetchCountryInfo } from '../../genericFunctions/GetAllCountries';
import { globalConfig } from '../../constants';
import * as utils from '../../utils/reuse'
// import GoogleLoginComponent from './GoogleLogin';
// import AppleLoginComponent from './AppleLogin';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { handleFirstNameChange, handleLastNameChange, trimFormData } from "../../utils/reuse";
import "../login/forms.scss";
import CustomPhoneNumber from "../../components/CustomPhoneNumber";
import { parsePhoneNumber } from 'react-phone-number-input'

const Signup = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);
    const toast = useRef('');
    const [checked, setChecked] = useState(false);
    const [country, setCountry] = useState([]);
    const [availableProviders, setAvailableProviders] = useState([]);
    const [phoneValue, setPhoneValue] = useState('');
    const [phoneError, setPhoneError] = useState("")

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        callingCode: '',
        password: '',
        confirmPassword: '',
        country: null,
        selectedProvider: 'Default'
    }

    const validationSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().trim()
                .required(t("validation.firstName"))
                .min(3, t("validation.firstNameMinMaxError"))
                .max(20, t("validation.firstNameMinMaxError")),
            lastName: Yup.string().trim()
                .required(t("validation.lastName"))
                .min(3, t("validation.lastNameMinMaxError"))
                .max(20, t("validation.lastNameMinMaxError")),
            email: Yup.string().trim()
                .email(t("validation.validEmail"))
                .required(t("validation.email")),
            country: Yup.string()
                .trim()
                .nullable()
                .required("validation.country"),
            selectedProvider: Yup.string().required(t('validation.serviceProvider')),
            password: Yup.string().trim()
                .required(t("validation.password"))
                .min(8, t("validation.inValidPasswordErr"))
                .max(20, t("validation.inValidPasswordErr"))
                .matches(/\d/, t("validation.inValidPasswordErr"))
                .matches(/[A-Za-z]/, t("validation.inValidPasswordErr"))
                .strict(true),
            confirmPassword: Yup.string().trim()
                .required(t("validation.confirmPassword"))
                .when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        t("validation.notMatchedErr")
                    )
                }),
        });
    }

    const submitHandler = formData => {
        
            const trimmedFormData = trimFormData(formData);
            const phone = {
                localPhoneNumber: phoneValue,
                callingCode: trimmedFormData?.callingCode ? trimmedFormData?.callingCode : '',
            }
            const additionalInfo = {
                selectedProvider: trimmedFormData.selectedProvider,
                country: trimmedFormData.country
            }
            const requestObj = { ...trimmedFormData }
            requestObj.phone = phone;
            requestObj.additionalInfo = additionalInfo;
            delete requestObj.callingCode;
            delete requestObj.selectedProvider;
            delete requestObj.country;
            const getResponse = (response) => {
                if (response.result === 'SUCCESS') {
                    formik.resetForm();
                    setPhoneValue('');
                    toast.current.show({ life: globalConfig.toastDisplayTime, severity: 'success', summary: t("toastSummary.success"), detail: t("login.signupSuccessful")})
                    setTimeout(() => {
                        props.renderFormState(true, "login")
                    }, [3000])
                } else {
                    const error = response.error;
                    const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                    toast.current.show({ life: globalConfig.toastDisplayTime, severity: error.severity, summary: t("toastSummary.error"), detail: t(`errorMsg.${errorMsg}`) })
                }
            }
            signup(requestObj, headers, dispatch, getResponse);
    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: submitHandler
    })

    useEffect(() => {
        let isValidPhone = parsePhoneNumber(formik.values.phone);
        if(!phoneValue) {
            setPhoneError(t("validation.phone"))
        } else if(isValidPhone?.nationalNumber.length <=6 || isValidPhone?.nationalNumber === undefined ) {
            setPhoneError(t("validation.phoneMinMaxError"))
        } else {setPhoneError("")}
    }, [formik.values.phone, phoneValue, t])


    const handleCountryChange = (e) => {
        const selectedValue = e.target.value;
        formik.setFieldValue('country', selectedValue);
        formik.setFieldValue('selectedProvider', '');
        if (selectedValue) {
            const selectedCountryData = country.find((countries) => countries.value === selectedValue);
            if (selectedCountryData) {
                const providers = selectedCountryData.provider.map(provider => provider.replace(/"/g, ''));
                setAvailableProviders(providers || []);
            }
        }
    };

    const handleCountryBlur = () => {
        formik.setFieldTouched('country', true);
    };

    useEffect(() => {
        const fetchCountryData = async () => {
            const params = {
                countries: '',
                info: ''
            }
            await fetchCountryInfo(params, dispatch, setCountry, toast, t);
        };

        fetchCountryData();
    }, [dispatch, setCountry, toast, t]);



    return (
        <>
            <div className="form-section">
                <div className="text-right form-top-link">
                    <p>{t("login.alreadyhaveAnAccount")} <Link onClick={() => props.renderFormState(true, 'login')}>{t("login.login")}</Link></p>
                </div>
                <div className="form">
                    <h3>{t("login.signup")}</h3>
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div className="input-field">
                            <PR.InputText id="signup-email" name="email" placeholder={t("placeholder.emailAddress")} className="w-12"
                                value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                            {formik.errors.email && formik.touched.email
                                ? <div className='error-message'>{formik.errors.email}</div>
                                : ''
                            }
                        </div>
                        <div className="input-field custom-input-field">
                            <div className='grid'>
                                <div className="col-12 lg:col-6 md:col-6">
                                    <PR.InputText id="signup-firstName" name="firstName" placeholder={t("placeholder.firstName")} className="w-12" keyfilter={"alphanum"} value={formik.values.firstName} onChange={(e) => handleFirstNameChange(e, formik)} onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.firstName && formik.touched.firstName
                                        ? <div className='error-message'>{formik.errors.firstName}</div>
                                        : ''
                                    }
                                </div>
                                <div className="col-12 lg:col-6 md:col-6">
                                    <PR.InputText id="signup-lastName" name="lastName" placeholder={t("placeholder.lastName")} className="w-12" keyfilter={"alphanum"}
                                        value={formik.values.lastName} onChange={(e) => handleLastNameChange(e, formik)} onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.lastName && formik.touched.lastName &&
                                        <div className='error-message'>{formik.errors.lastName}</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="input-field">
                            <div className="flex flex-column" >
                                <CustomPhoneNumber formik={formik} t={t} setPhoneValue={setPhoneValue} phoneError={phoneError}/>
                            </div>
                        </div>

                        <div className="input-field">
                            <PR.Dropdown
                                id="country"
                                value={formik.values.country}
                                name="country"
                                options={country}
                                onChange={handleCountryChange}
                                onHide={handleCountryBlur}
                                resetFilterOnHide
                                optionLabel="label"
                                optionValue="value"
                                showClear placeholder={t('placeholder.selectCountry')}
                                style={{ 'width': '100%' }}
                                filter
                                filterBy="label"
                            />
                            {formik.errors.country && formik.touched.country ? <div className='error-message'>{t(formik.errors.country)}</div> : ''}
                        </div>

                        {formik.values.country &&
                            <div className="input-field">
                                {formik.values.country === 'US' ? 
                                <PR.Dropdown
                                    id="selectedProvider"
                                    name="selectedProvider"
                                    value={formik.values.selectedProvider}
                                    options={availableProviders.length ? availableProviders : ['Default']}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Select a Service Provider"
                                    style={{ 'width': '100%' }}
                                    resetFilterOnHide
                                    filter
                                /> :
                                    <PR.InputText id="selectedProvider" name="selectedProvider" placeholder="Service Provider" className="w-12"
                                        value={formik.values.selectedProvider} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                }
                                {formik.errors.selectedProvider && formik.touched.selectedProvider ? <div className='error-message'>{formik.errors.selectedProvider}</div> : ''}
                            </div>
                        }

                        <div className="input-field">
                            <div className='grid'>
                                <div className="col-12 lg:col-6 md:col-6">
                                    <PR.Password feedback={false} id='signup-password' name='password' placeholder={t("placeholder.password")} className="w-12" toggleMask autoComplete="off"
                                        value={formik.values.password} onChange={e => utils.removeWhiteSpacesInPassword(e, formik, "password")} onBlur={formik.handleBlur} />
                                    {formik.errors.password && formik.touched.password
                                        ? <div className='error-message'>{formik.errors.password}</div>
                                        : ''
                                    }
                                </div>
                                <div className="col-12 lg:col-6 md:col-6">
                                    <PR.Password feedback={false} id='signup-confirmPassword' name='confirmPassword' placeholder={t("placeholder.confirmPassword")} className="w-12" toggleMask autoComplete="off"
                                        value={formik.values.confirmPassword} onChange={e => utils.removeWhiteSpacesInPassword(e, formik, "confirmPassword")} onBlur={formik.handleBlur} />
                                    {formik.errors.confirmPassword && formik.touched.confirmPassword
                                        ? <div className='error-message'>{formik.errors.confirmPassword}</div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex checkbox">
                            <PR.Checkbox inputId="account" name="account" checked={checked} onChange={e => setChecked(e.checked)} />
                            <label htmlFor="account">{t("login.checkboxlabel")}</label>
                        </div>
                        <PR.Button label={t("login.signup")} type='submit' disabled={!formik.isValid || !checked || phoneError} className="login-btn signup-btn mt-3" />
                        {/* <span className="or-span">{t("login.or")}</span>
                        <div className="flex mobile-flex gap-3">
                            <GoogleLoginComponent displayName={t("login.googleSignUp")} renderFormState={props.renderFormState} type={"signup"} />
                            <AppleLoginComponent displayName={t("login.appleSignUp")} renderFormState={props.renderFormState} />
                        </div> */}
                    </form>
                    <PR.Toast ref={toast} position='top-right' />
                </div>
                <div className="form-footer">
                    <p>{t("login.captchaText")} <Link to="/privacy-policy" onClick={() => props.renderFormState(false, "")}>{t("login.privacyPolicy")}</Link> {t("login.and")} <Link to="/terms-and-conditions" onClick={() => props.renderFormState(false, "")}>{t("login.termsOfService")}</Link>.</p>
                </div>
            </div>
        </>
    )
}

export default Signup;