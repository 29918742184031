import { createSlice } from '@reduxjs/toolkit';
const getsessionData = localStorage.getItem('sessionData');
const getidPath = localStorage.getItem('USERID-PATH')

const sessionData = (getsessionData === 'undefined' || getsessionData === "") ? JSON.stringify(getsessionData) : (getsessionData !== null) ? JSON.parse(getsessionData): {};
const isLoggedIn = (getsessionData === 'undefined' || getsessionData === "") ? false : getsessionData !== null ? true : false;
const idPath = (getidPath === 'undefined' || getidPath === "") ? JSON.stringify(getidPath) : (getidPath !== null) ? JSON.parse(getidPath): {};

const initialAuthState = {
    isLoggedIn: isLoggedIn,
    sessionData: sessionData,
    idPath: idPath,
};

const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        onLogin(state, action) {
            const storedLoginInfo = {
                sessionId: action.payload.sessionId,
                userId: action.payload.userId,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                phone: action.payload.phone,
                signupType: action.payload.signupType
            }
            state.isLoggedIn = true;
            state.sessionData = storedLoginInfo;
            localStorage.setItem('sessionData', JSON.stringify(storedLoginInfo));
        },
        onLogout(state) {
            state.isLoggedIn = false;
            state.sessionData = {};
            localStorage.removeItem('sessionData');
            localStorage.removeItem('ALLCOUNTRIES');
            localStorage.removeItem('PHONECOUNTRY');
            localStorage.removeItem('PAYMENT-RESPONSE');
        },
        storeIdPath(state, action) {
            state.idPath = action.payload
            localStorage.setItem('USERID-PATH', JSON.stringify(action.payload));
        },
        deleteStoreIdPath(state){
            state.idPath = {};
            localStorage.removeItem('USERID-PATH');
        }
    },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;