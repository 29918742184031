import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  isIOS,
  osVersion,
  isMobile,
} from 'react-device-detect';
import { globalConfig } from "../../constants";
import EdiText from "react-editext";
import * as PR from "../../prime-modules";
import { getUserEsimList, updateTag } from "../../services/api";
import { esimIccidActions } from "../../store/esim";
import {
  formatBundleExpiration,
  modifyDateFormat,
  checkDataSize,
  getDayLabel
} from "../../utils/reuse";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import EsimActivationDialog from "../../shared/components/EsimActivationDialog";

const MyEsims = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);
  const userData = useSelector((state) => state.auth.sessionData);
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { sessionid: userData.sessionId, afid: affiliateTrackingData };
  }, [userData.sessionId, affiliateTrackingData]);

  const [loading, setLoading] = useState(false);
  const [esimList, setEsimList] = useState([]);
  const [qrDisplay, setQRDisplay] = useState(false);
  const [qrData, setQRData] = useState(false);
  const [activeeSIM, setActiveeSIM] = useState(false)
  const [activeBundleDialog, setActiveBundleDialog] = useState(false);
  const [activeBundlesData, selectedActiveBundlesData] = useState([]);
  const [esimStatus, setEsimStatus] = useState(null);
  const [esimFilteredList, setEsimFilteredList] = useState([]);

  const esimStatusList = [
    { label: t("myeSIMs.installed"), value: "Installed" },
    { label: t("myeSIMs.enabled"), value: "Enabled" },
    { label: t("myeSIMs.released"), value: "Released" },
    { label: t("myeSIMs.downloaded"), value: "Downloaded" },
    { label: t("myeSIMs.disabled"), value: "Disabled" },
    { label: t("myeSIMs.unavailable"), value: "Unavailable" },
    { label: t("myeSIMs.unknown"), value: "Unknown" },
  ];

  const onEsimStatusChange = (esims, value) => {
    setEsimStatus(value);
    const esimValues = value
      ? esims.filter((list) => list.status === value)
      : esims;
    setEsimFilteredList(esimValues);
  };

  // Get list of esims
  const getEsims = useCallback(() => {
    setLoading(true);
    const getEsimList = (response) => {
      if (response.result === "SUCCESS") {
        setLoading(false);
        const esimValues =
          response.data && response.data
            ? response.data.sort(
              (a, b) => new Date(b.createdTs) - new Date(a.createdTs)
            )
            : [];

        const bundlesWithAtiveStatus = esimValues.map(esim => {

          const activeBundles = esim?.activeBundlesData?.map((bundle) => {
            let today = new Date();
            const startDate = new Date(bundle.bundleStartTime);
            const endDate = new Date(bundle.bundleEndTime);
            if ((startDate <= today) && (today <= endDate) && (bundle.remainingData > 0)) {
              bundle.bundleStatus = "Active";
            }
            return bundle
          }).filter(bundle => bundle.bundleStatus === "Active");

          esim.activeBundles = activeBundles?.length > 0 ? activeBundles : [];
          return esim;
        })
        setEsimList(bundlesWithAtiveStatus);
        setEsimFilteredList(bundlesWithAtiveStatus);
      } else {
        setLoading(false);
        const error = response.error;
        const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
        toast.current.show({
          life: globalConfig.toastDisplayTime,
          severity: error.severity,
          summary: t("toastSummary.error"),
          detail: t(`errorMsg.${errorMsg}`),
        });
      }
    };
    getUserEsimList(headers, dispatch, getEsimList);
  }, [dispatch, headers, t]);

  useEffect(() => {
    getEsims();
  }, [getEsims]);

  const tagComponent = (esim) => {
    return (
      <EdiText
        name="tag"
        value={esim.tag}
        type="text"
        placeholder={t("myAccount.screen.grid.tag")}
        onSave={(e) => tagHandleSave(esim, e)}
        validation={validateTagValue}
        validationMessage={t("validation.tagMinMaxError")}
        onCancel={(e) => e}
      />
    );
  };

  const tagHandleSave = (rowData, value) => {
    if (validateTagValue(value) && rowData.tag !== value) {
      const iccid = rowData.iccid;
      const request = {
        userId: rowData.userId,
        iccid: iccid,
        tag: value,
      };
      setLoading(true);
      const tagResponse = (response) => {
        if (response.result === "SUCCESS") {
          setLoading(false);
          toast.current.show({
            life: globalConfig.toastDisplayTime,
            severity: "success",
            summary: t("toastSummary.success"),
            detail: t("myAccount.tagSuccess.sucessMsg"),
          });
        } else if (response.result === "FAILED" && response.error) {
          setLoading(false);
          const error = response.error;
          const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
          toast.current.show({
            life: globalConfig.toastDisplayTime,
            severity: error.severity,
            summary: t("toastSummary.error"),
            detail: t(`errorMsg.${errorMsg}`),
          });
        }
        getEsims();
      };
      updateTag(request, headers, dispatch, tagResponse);
    }
  };

  const validateTagValue = (text) => {
    if (text.length === 0) {
      return true;
    } else if (text.length > 0) {
      return text.length >= 3 && text.length <= 10;
    }
  };

  const displayQRCode = (qrcode) => {
    const qrCode = "data:image/png;base64," + qrcode;
    setQRData(qrCode);
    setQRDisplay(true);
  };

  const downloadQRCode = (qrcode) => {
    let downloadLink = document.createElement("a");
    downloadLink.href = qrcode;
    downloadLink.download = "QR_Code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const viewEsimsHandler = (esim) => {
    props.setUserId(esim.userId)
    const esimIccid = {
      esimIccid: esim.iccid,
      tag: esim.tag,
    };
    dispatch(esimIccidActions.setEsim(esimIccid));
    props.setDisplayScreen(1);
  };

  const topupEsimsHandler = (esim) => {
    const addeBundleData = {
      esimIccid: esim.iccid,
      tag: esim.tag,
    };
    dispatch(esimIccidActions.setEsim(addeBundleData));
    props.setDisplayScreen(2);
  };

  const renderProgressBarTemplate = (bundle) => {
    const bundleEndTime = bundle.bundleEndTime;
    const bundleStartTime = bundle.bundleStartTime;
    const date = new Date(bundle.bundleStartTime).getFullYear();
    const remainingDays = formatBundleExpiration(bundleStartTime, bundleEndTime, t).remainingDays;
    return (
      <>
        <p>{formatBundleExpiration(bundleStartTime, bundleEndTime, t).output}
        <b>{remainingDays} {getDayLabel(remainingDays, t)}</b>
        </p>
        <PR.ProgressBar
          value={
            formatBundleExpiration(bundleStartTime, bundleEndTime, t)
              .usedDataPercentage
          }
        ></PR.ProgressBar>
        {date !== 1 && <p className="text-right">
          {t("myeSIMs.expiresOn")} <span>{modifyDateFormat(bundleEndTime)}</span>
        </p>}
      </>
    );
  };


  const filterBundles = (bundles)=> {
    const currentTime = new Date().toISOString();
    return bundles?.filter(bundle => {
        const startTime = new Date(bundle.bundleStartTime).toISOString();
        const endTime = new Date(bundle.bundleEndTime).toISOString();
        return currentTime >= startTime && currentTime <= endTime;
    });
}

const displayMultipleBundles = ({ activeBundlesData }) => {
  setActiveBundleDialog(true);
  activeBundlesData &&  selectedActiveBundlesData(filterBundles(activeBundlesData));
};

  const addDataHandler = () => {
    navigate("/global-esims")
    dispatch(scrollToBundlesActions.setScrollToBundles(true));
  }

  return (
    <>
      {
        loading
          ? <div className="loading-div">
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
          </div>
          : <div className="esims-section">
            <div className="card status-dropdown">
              <PR.Dropdown
                filter
                value={esimStatus}
                resetFilterOnHide
                options={esimStatusList}
                onChange={(e) => onEsimStatusChange(esimList, e.value)}
                optionLabel="label"
                optionValue="value"
                placeholder={t("myAccount.screen.esimList.selectStatus")}
                showClear
              />
            </div>
            <h3>{t("myeSIMs.youreSIMs")}</h3>
            <div className="grid">

              {esimFilteredList.map((esim) => {
                return (
                  <div className="col-12 lg:col-6 md:col-6" key={esim.iccid}>
                    <div className="esim-box">
                      <ul className="flex align-items-center justify-content-between user-edit-ul">
                        <li>
                          <p className="id_p">{esim.iccid}</p>
                        </li>
                        <li>
                          <span className={"status-box " + esim.status}>
                            {esim.status}
                          </span>
                        </li>
                      </ul>
                      <ul className="flex align-items-center justify-content-between user-edit-ul">
                        <li>
                          <span>{tagComponent(esim)}</span>
                        </li>
                      </ul>

                      {esim.status !== "Released" && (
                        <div className="text-center active-esim-button">
                          <PR.Button
                            label={esim.count + " " + (esim.count > 1 ? t("myeSIMs.activeBundles") : t("myeSIMs.activeBundle"))}
                            className="p-button-primary multiple-bundles-btn"
                            onClick={() => displayMultipleBundles(esim)}
                            disabled={esim.count === 0}
                          />
                        </div>
                      )}

                      <PR.Dialog
                        header={t("myeSIMs.bundleList")}
                        className="multiple-bundles-dialog"
                        draggable={false}
                        blockScroll={true}
                        resizable={false}
                        style={{ width: "40%" }}
                        visible={activeBundleDialog}
                        onHide={() => setActiveBundleDialog(false)}
                      >
                        {activeBundlesData.map((bundle, index) => {
                          let percentage = bundle.totalData ? (((bundle.totalData - bundle.remainingData) / bundle.totalData) * 100) : 0;
                          return (
                            <div className="active-bundles-box" key={index}>
                              <ul className="user-edit-ul flex align-items-center justify-content-between">
                                <li>
                                  <b>{t("myAccount.currentPlan")}</b>
                                </li>
                                <li>{bundle.description}</li>
                              </ul>
                              <>
                                <div className="card progress-bar">
                                  <p>
                                    <span>
                                      {checkDataSize(
                                        bundle.totalData - bundle.remainingData
                                      )}
                                    </span>{" "}
                                    {t("myeSIMs.of")} {checkDataSize(bundle.totalData)} {t("myeSIMs.used")}
                                  </p>
                                  <PR.ProgressBar
                                    value={percentage}
                                  ></PR.ProgressBar>
                                  <p className="text-right">
                                    <span>
                                      {checkDataSize(bundle.remainingData)}
                                    </span>{" "}
                                    {t("myeSIMs.remaining")}
                                  </p>
                                </div>
                                <div className="card progress-bar">
                                  {renderProgressBarTemplate(bundle)}
                                </div>
                              </>
                            </div>
                          );
                        })}
                      </PR.Dialog>
                    {esim.smdpAddress && esim.matchingId && <EsimActivationDialog smdpAddress={esim.smdpAddress} matchingId={esim.matchingId} activeeSIM={activeeSIM} setActiveeSIM={setActiveeSIM}/>}

                      {esim.status === "Released" && (
                        <div className="esim-not-installed">
                          {esim.qrCodeImage &&
                            <i
                              className="pi pi-qrcode"
                              onClick={() => displayQRCode(esim.qrCodeImage)}
                            ></i>
                          }
                          <PR.Dialog
                            visible={qrDisplay}
                            onHide={() => setQRDisplay(false)}
                            header={t("myeSIMs.downloadQR")}
                            draggable={false}
                            blockScroll={true}
                            resizable={false}
                            className="download-qr-dialog"
                          >
                            <div className="paymentsummary-qr-section">
                              <img
                                src={qrData}
                                id="qrCodeEl"
                                alt="qrcode"
                                className="qr-code"
                              />
                              <p>{t("payment.paymentQRCodeScan")}</p>
                              <PR.Button
                                label={t("myeSIMs.download")}
                                className="p-button-primary download-button"
                                onClick={() => downloadQRCode(qrData)}
                              />
                            </div>
                          </PR.Dialog>
                          <p>{t("myeSIMs.notInstalled")}</p>
                          <p className="font-weight-light">
                            {t("myeSIMs.installeSIM")}
                          </p>
                        </div>
                      )}

                      <ul className="buttons-ul flex align-items-center justify-content-between">
                        <li>
                          <PR.Button
                            label={t("myeSIMs.eSIMDetails")}
                            icon="pi pi-qrcode"
                            className="choose-plans-button p-button-rounded"   
                            onClick={() => viewEsimsHandler(esim)}
                          />
                        </li>
                        {(esim.status === "Released" && isMobile && isIOS && (osVersion >= "17.4")) ?
                          <li>
                            <PR.Button
                              onClick={() => setActiveeSIM(true)}
                              label={t("myeSIMs.activateeSIM")}
                              icon="pi pi-check-circle"
                              className="choose-plans-button p-button-rounded"
                            />
                          </li> : <li>
                            <PR.Button
                              label={t("myeSIMs.topupeSIM")}
                              icon="pi pi-credit-card"
                              className="choose-plans-button p-button-rounded"
                              onClick={() => topupEsimsHandler(esim)}
                            />
                          </li>}
                      </ul>
                    </div>
                  </div>
                );
              })}
              {!loading &&
                <div className="col-12 lg:col-6 md:col-6" >
                  <div className="esim-box not-installed-esim-box">
                    <div>
                      <i className="pi pi-plus-circle" onClick={addDataHandler}></i>
                      <p onClick={addDataHandler}>{t("myeSIMs.addeSIMandData")}</p>
                    </div>
                  </div>
                </div>}
            </div>
          </div>
      }

      <PR.Toast ref={toast} position='top-right' />
    </>
  );
};

export default MyEsims;
