import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import "../page-not-found/PageNotFound.scss";
import { useTranslation } from "react-i18next";
import { globalConfig } from "../../constants";
import getAssets from '../../assets/data/brandingAssets.json';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{globalConfig.domainName} | {t("pageNotFound.title")}</title>
      </Helmet>

      {/* Header Section */}
      <section className="header-section maintenance-section page-not-found">
        <div className="header">
          <header>
            <Link to="/"><PR.Image src={getAssets.logo.logo_light} className="esim-icon" alt="eSIM Icon" /></Link>
          </header>
        </div>
        <div className="grid header-text">
          <div className="col-12 md:col-10 lg:col-7">
            <div className="header-text-box">
              <h1>404 Error <span>{t("pageNotFound.title")}</span></h1>
              <p>{t("pageNotFound.description")}</p>
              <Link to="/" className="go-to-home"><i className="pi pi-chevron-left"></i> {t("pageNotFound.goToHomePage")}</Link>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default PageNotFound;