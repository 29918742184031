import { createSlice } from '@reduxjs/toolkit';

const getAllCountries = localStorage.getItem('ALLCOUNTRIES');
const getLoggedInUserPhoneCountry = localStorage.getItem('PHONECOUNTRY');
const getDefaultCallingCode = localStorage.getItem('DEFAULTCALLINGCODE');

const allCountries = (getAllCountries === 'undefined' || getAllCountries === "") ? JSON.stringify(getAllCountries) : (getAllCountries !== null) ? JSON.parse(getAllCountries): {};
const phoneCountry = (getLoggedInUserPhoneCountry === 'undefined' || getLoggedInUserPhoneCountry === "") ? JSON.stringify(getLoggedInUserPhoneCountry) : (getLoggedInUserPhoneCountry !== null) ? JSON.parse(getLoggedInUserPhoneCountry): {};
const defaultCallingCode = (getDefaultCallingCode === 'undefined' || getDefaultCallingCode === "") ? JSON.stringify(getDefaultCallingCode) : (getDefaultCallingCode !== null) ? JSON.parse(getDefaultCallingCode): {};


const initialValues = {
    allCountries: allCountries,
    phoneCountry: phoneCountry,
    defaultCallingCode: defaultCallingCode
}

export const allCountriesSlice = createSlice({
    name: 'allCountries',
    initialState: initialValues,
    reducers: {
        setAllCountries(state, action) {
            state.allCountries = action.payload
            localStorage.setItem("ALLCOUNTRIES", JSON.stringify(action.payload));
        },
        setPhoneCountry(state,action){
            state.phoneCountry = action.payload
            localStorage.setItem("PHONECOUNTRY", JSON.stringify(action.payload));
        },
        setDefaultCallingCode(state,action){
            state.defaultCallingCode = action.payload
            localStorage.setItem("DEFAULTCALLINGCODE", JSON.stringify(action.payload));
        }
    },
})

export const allCountriesActions = allCountriesSlice.actions;
export default allCountriesSlice.reducer;
