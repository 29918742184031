import React, {useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as PR from "../../prime-modules/index";

const DeviceCompatibilty = (props) => {
    const {t} = useTranslation();
    const [checked, setChecked] = useState(false);
    const iccid = useSelector(state => state.esim.esimIccid);
    const isAuth = useSelector(state => state.auth.isLoggedIn);

    useEffect(() => {
        if(isAuth && iccid!==""){
            props.setCompatibilityCheck(true);
        }
    }, [iccid, props,t, isAuth])

    const confirmationMsg = (val) => {
        setChecked(val);
        props.setCompatibilityCheck(val);
    }

    return (
        <>
            {iccid === "" &&
                <section className="compatibility-section">
                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <div className="compatibility-box">
                                <div className="pt-0">
                                    <div className="field-checkbox">
                                        <PR.Checkbox name="confirmatioText" checked={checked} onChange={e => confirmationMsg(e.checked)} />
                                        <label htmlFor="confirmatioText" className="confirmationText">{t("payment.confirmationText")} <Link className="device-link" target="_blank" to="/compatible-devices">{t("payment.eSimCompatible")}</Link></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}
export default DeviceCompatibilty;