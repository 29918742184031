import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as PR from "../../prime-modules";


const EsimActivationDialog = ({smdpAddress,matchingId, activeeSIM, setActiveeSIM}) => {

    const { t } = useTranslation();
  return (
    <PR.Dialog header="" visible={activeeSIM} headerStyle={{ padding: 4}} draggable={false} blockScroll={true} className="forms-dialog-popup" resizable={false} style={{ width: '85vw'}} onHide={() => { if (!activeeSIM) return; setActiveeSIM(false); }}>
    <p className="esim-popup-title">
      {t("myeSIMs.areyousure")}
    </p>
    <p className="esim-popup-subtitle">
    {t("myeSIMs.areYouSureDescription")}
    </p>
      <div className="esim-popup-button">
      <Link to={`https://esimsetup.apple.com/esim_qrcode_provisioning?carddata=LPA:1$${smdpAddress}$${matchingId}`} onClick={()=>setActiveeSIM(false)} target='_blank' rel='noreferrer'>
        <PR.Button
          label={t("myeSIMs.continue")}
          className="choose-popup-button p-button-rounded"
        />
         </Link>
      </div>
  </PR.Dialog>
  )
}

export default EsimActivationDialog
