import React, { useRef, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { esimIcon, partnerImage } from "../../assets/images";
import { useTranslation } from "react-i18next";
import BundlesList from "../../components/EsimBundles";
import HeaderMenu from "../../components/layout/HeaderMenu";
import "../home/Home.scss";
import faq_en from "../../assets/data/faq_en.json";
import faq_es from "../../assets/data/faq_es.json";
import faq_pt from "../../assets/data/faq_pt.json";
import { useDispatch, useSelector } from "react-redux";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import getAssets from '../../assets/data/brandingAssets.json';
import { globalConfig } from "../../constants";
import ContactForm from "../../components/ContactForm";
import { ScrollTo } from "../../utils/ScrollTo";

const Home = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const scrollToBundlesState = useSelector(state => state.scrollToBundles.scrollToBundlesState);
  const ref = useRef(null);
  const [faq, setFaq] = useState([]);

  const handleClick = () => {
    ScrollTo(ref, -120);
  };

  useEffect(() => {
    if (scrollToBundlesState) {
      handleClick();
      dispatch(scrollToBundlesActions.deleteSrollToBundles());
    }

    const defaultLang = i18n.language.split("-")[0];
    if (defaultLang === "en") {
      setFaq(faq_en);
    } else if (defaultLang === "es") {
      setFaq(faq_es);
    } else if (defaultLang === "pt") {
      setFaq(faq_pt);
    }
  }, [t, i18n, scrollToBundlesState, dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{globalConfig.domainName} | {t("home.title")}</title>
      </Helmet>
      {/* Header Section */}
      <section className="header-section" style={{ background: `url(${getAssets.home.main_banner}) no-repeat center` }}>
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid header-text">
          <div className="col-12 lg:col-8 md:col-8">
            <div className="header-text-box">
              <h1>{t("home.topHeader")}</h1>
              <p>{t("home.topHeaderDescription")}</p>
              <PR.Button
                label={t("home.chooseYourPlanBtn")}
                className="goto-plans-button p-button-rounded"
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Options Section */}
      <section className="options-section">
        <div className="grid">
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={getAssets.home.panel1} alt="Instant Activation" />
              <h2>{t("home.infoCard.step1")}</h2>
              <p>{t("home.infoCard.description1")}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={getAssets.home.panel2} alt="Attractive Rates" />
              <h2>{t("home.infoCard.step2")}</h2>
              <p>{t("home.infoCard.description2")}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={getAssets.home.panel3} alt="Global Coverage" />
              <h2>{t("home.infoCard.step3")}</h2>
              <p>{t("home.infoCard.description3")}</p>
            </div>
          </div>
        </div>

        {/* SmartPath Section */}
        <section className="smartpath-section">
          <h2>{t("home.smartpath.heading")}</h2>
          <p>{t("home.smartpath.subHeading")}</p>
          <p className="mt-5">{t("home.platformText")}</p>
          <ul className="list-none">
            <li className="left right"><span>{t("home.smartpath.option1")}</span></li>
            <li className="left right"><span className="custom-width">{t("home.smartpath.option2")} <br/> {t("home.smartpath.option2-1")}</span></li>
            <li className="left right"><span>{t("home.smartpath.option3")}</span></li>
            <li className="left right"><span>{t("home.smartpath.option4")}</span></li>
            <li className="left right"><span>{t("home.smartpath.option5")}</span></li>
          </ul>

        </section>
        {/* #6d69fdfc */}
      </section>

      {/* unlock world Section */}
      {getAssets.home.unlockworld_section_display &&
        <section className="offer-section connectivity-section">
          <div className="grid grid-nogutter column-reverse align-items-center">
            <div className="col-12 lg:col-6 md:col-6 text-center">
              <div className="option-box">
                <PR.Image
                  className="hand-iphone-img"
                  src={getAssets.home.unlock_world}
                  alt="Connectivity"
                />
              </div>
            </div>
            <div className="col-12 lg:col-6 md:col-6">
              <div className="option-box">
                <h2>{t("globalEsims.connectivityText")}</h2>
                <p>{t("globalEsims.connectivityDescription")}</p>
                {/* <PR.Button label={t("home.createAccount")} className="choose-plans-button" onClick={handleClickSignup} rounded /> */}
              </div>
            </div>
          </div>
        </section>}

      {/* Plans Section */}
      {getAssets.home.plans_section_display && <section className="plan-section" ref={ref}>
        <div className="grid align-items-center">
          <h2>{t("home.consfigurePlan")}</h2>
          <PR.Image src={esimIcon} className="esim-icon" alt="eSIM Icon" />
        </div>
        <div className="card">
          <div className="bundles-section">
            <BundlesList />
          </div>
        </div>
      </section>}

      {/* Benefits Section */}
      {getAssets.home.benefits_section_display && <section className="benefits-section">
        <div className="grid">
          <div className="col-12 lg:col-6 md:col-6">
            <div className="option-box">
              <h2>{t("home.benefits.title")}</h2>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={getAssets.home.benifit_section.multi_network_connectivity}
                    alt="Multi-Network Connectivity"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{t("home.benefits.feature1")}</h3>
                  <p>{t("home.benefits.description1")}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={getAssets.home.benifit_section.fast_connections}
                    alt="Fast Connections"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{t("home.benefits.feature2")}</h3>
                  <p>{t("home.benefits.description2")}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={getAssets.home.benifit_section.support_24_7}
                    alt="Support 24x7"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{t("home.benefits.feature3")}</h3>
                  <p>{t("home.benefits.description3")}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={getAssets.home.benifit_section.easy_installation}
                    alt="Easy Installation"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{t("home.benefits.feature4")}</h3>
                  <p>{t("home.benefits.description4")}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={getAssets.home.benifit_section.best_pricing_plans}
                    alt="Best Pricing Plans"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{t("home.benefits.feature5")}</h3>
                  <p>{t("home.benefits.description5")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-6 md:col-6 text-center">
            <div className="option-box">
              <PR.Image
                className="benefits-img"
                src={getAssets.home.benifit_section.right_image}
                alt="Benefits"
              />
            </div>
          </div>
        </div>
      </section>}

      {/* Partners Section */}
      {getAssets.home.partners_section_display && <section className="partners-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-7 md:col-7">
            <div className="partners-text-box flex align-items-center justify-content-center">
              <div>
                <h2>{t("home.partner.title")}</h2>
                <p>{t("home.partner.description")}</p>
                <Link
                  className="view-all-button"
                  to='/partners'>{t("home.partner.button")}</Link>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-5 md:col-5 text-center">
            <div className="option-box">
              <PR.Image
                className="benefits-img"
                src={partnerImage}
                alt="Benefits"
              />
            </div>
          </div>
        </div>
      </section>}

      {/* FAQ Section */}
      {getAssets.home.faq_section_display && <section className="faq-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-10 md:col-10 md:col-offset-1 lg:col-offset-1">
            <h2>{t("faq.mainHeading")}</h2>
            {faq &&
              faq.length > 0 &&
              faq.slice(0, 1).map((item, i) => (
                <PR.Accordion key={i}>
                  {item.QA.slice(0, 4).map((qa, j) => (
                    <PR.AccordionTab header={qa.question} key={j}>
                      {qa.answer.map((an, h) => (
                        <div key={h}>
                          {an.content_heading && (
                            <p><strong>{an.content_heading}</strong></p>
                          )}
                          {an.content && (
                            <p>{an.content}</p>
                          )}
                          {an.olist?.length > 0 && (
                            <ol>
                              {an.olist.map((anl, nh) => (
                                <li key={nh}>{anl}</li>
                              ))}
                            </ol>
                          )}
                          {an.ulist?.length > 0 && (
                            <ul>
                              {an.ulist.map((anl, nh) => (
                                <li key={nh}>{anl}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </PR.AccordionTab>
                  ))}
                </PR.Accordion>
              ))}
          </div>
        </div>
        <div className="text-center faq-custom-margin">
          <Link to="/faq" className="view-all-button">{t("faq.checkoutFaqs")}</Link>
        </div>
      </section>}

      {/* Form Section */}
      <section className="about-partners-section" ref={ref}>
        <div className="grid align-items-center">
          <div className="col-12 lg:col-5 md:col-6">
            <div className="partners-text-box flex justify-content-center">
              <div>
                <h2>{t("home.formHeadline")}</h2>
                <p>{t("aboutUs.youCanReachUs")} <Link to={`mailto:${globalConfig.partnersEmail}`}>{globalConfig.partnersEmail}</Link></p>
                <ContactForm formType={"home"} toast={toast} />
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-7 md:col-6 text-center">
            <div className="option-box">
              <PR.Image className="benefits-img" src={getAssets.feature.contact_featureImage} alt="Benefits" />
            </div>
          </div>
        </div>
      </section>
      <PR.Toast ref={toast} position='top-right' />
    </HelmetProvider>
  );
};

export default Home;