import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import "../maintenance/Maintenance.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCountriesList, notifyemail } from "../../services/api";
import * as Yup from 'yup'
import { globalConfig } from "../../constants";
import { useFormik } from "formik";
import getAssets from '../../assets/data/brandingAssets.json';

const Maintenance = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [count, setCount] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);
  const siteMaintenance = useSelector(state => state.sitemaintenance.sitemaintenanceInfo);
  const isUnderMaintenance = (siteMaintenance === 'true') ? true : false;
  const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
  const headers = useMemo(() => {
      return {afid: affiliateTrackingData };
  }, [affiliateTrackingData]);

  const getCountries = useCallback(async () => {
    getCountriesList("countries", headers, dispatch, (response) => {
    })
  }, [dispatch, headers])

  useEffect(() => {
    isUnderMaintenance && getCountries();
  }, [getCountries, isUnderMaintenance])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setCount(count + 1); // Update state using setCount
      isUnderMaintenance && getCountries();
    }, 10000);

    return () => {
      clearTimeout(timerId); // Cleanup the timeout on component unmount
    };
  }, [getCountries, isUnderMaintenance, count])


  const formInitialValues = {
    email: "",
  }

  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string().trim()
        .email(t('validation.validEmail'))
        .required(t('validation.email'))
    });
  }

  const submitHandler = (formData) => {
    setDisableBtn(true);
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        toast.current.show({ life:globalConfig.toastDisplayTime, severity: 'success', summary:t('toastSummary.success'), detail: t('notifyEmail.successMessage') });
        formik.resetForm();
      } else {
        const error = response.error;
        const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
        toast.current.show({ life:globalConfig.toastDisplayTime, severity: error.severity, summary:t('toastSummary.error'), detail: t(`errorMsg.${errorMsg}`) })
      }
      setDisableBtn(false);
    }
    const emailVal = formData.email.trim();
    const emailRequest = {
      email: emailVal
    }
    notifyemail(emailRequest, headers, dispatch, getResponse)
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationSchema,
    onSubmit: submitHandler
  });


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{globalConfig.domainName} | {t("siteMaintenance.title")}</title>
      </Helmet>
    <PR.Toast ref={toast} position="top-right" />
      {/* Header Section */}
      <section className="header-section maintenance-section">
        <div className="header">
          <header>
            <Link to="/"><PR.Image src={getAssets.logo.logo_light} className="esim-icon" alt="eSIM Icon" /></Link>
          </header>
        </div>
        <div className="grid header-text">
          <div className="col-12 md:col-10 lg:col-7">
            <div className="header-text-box">
              <h1><span>{t("siteMaintenance.subTitle")}</span> {t("siteMaintenance.title")}</h1>
              <p>{t("siteMaintenance.description")}</p>
              <div className="email-block">
                  <form onSubmit={formik.handleSubmit}>
                    <PR.InputText id="email" name="email" value={formik.values.email} placeholder={t("placeholder.email")} onChange={formik.handleChange} onBlur={formik.handleBlur} className="w-12" autoComplete="off" />
                    <PR.Button className="send-button p-button-rounded" icon="pi pi-envelope" disabled={disableBtn}/>
                    {formik.touched.email && formik.errors.email &&
                     <div className='error-message'>{formik.errors.email}</div>
                    }
                  </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Maintenance;