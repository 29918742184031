import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as PR from '../prime-modules/index';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { globalConfig } from '../constants';
import { handleFirstNameChange, handleLastNameChange, trimFormData, displayDefaultPhone } from '../utils/reuse';
import { contact } from '../services/api';
import "../assets/scss/main.scss";
import CustomPhoneNumber from '../components/CustomPhoneNumber';
import { getPhoneObj } from '../genericFunctions/GetAllCountries';

const ContactForm = ({ formType, toast }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [disableBtn, setDisableBtn] = useState(false);
    const isAuth = useSelector((state) => state.auth.isLoggedIn);
    const userData = useSelector((state) => state.auth.sessionData);
    const getDefaultCallingCode = useSelector((state) => state.allCountries.defaultCallingCode);
    const [phoneValue, setPhoneValue] = useState('');
    const affiliateTrackingData = useSelector(
        (state) => state.affiliateTracking.affiliateTrackingInfo
    );
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);

    const formInitialValues = {
        firstName: isAuth ? userData?.firstName : "",
        lastName: isAuth ? userData?.lastName : "",
        email: isAuth ? userData?.email : "",
        phone: displayDefaultPhone(isAuth, userData, getDefaultCallingCode),
        body: "",
        type: formType,
    };

    const validationSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string()
                .trim()
                .required("validation.firstName")
                .min(3, "validation.firstNameMinMaxError")
                .max(20, "validation.firstNameMinMaxError"),
            lastName: Yup.string()
                .trim()
                .min(3, "validation.lastNameMinMaxError")
                .max(20, "validation.lastNameMinMaxError"),
            email: Yup.string()
                .trim()
                .email("validation.validEmail")
                .required("validation.email"),
            body: Yup.string().trim().required("validation.contactMessage"),
        });
    };

    const handleSubmit = (formData) => {
        const trimmedFormData = trimFormData(formData);
        const requestObj = { ...trimmedFormData }
        requestObj.phone = getPhoneObj(phoneValue, isAuth, userData, formData);
        delete requestObj.callingCode;
        setDisableBtn(true);
        const response = (response) => {
            if (response.result === "SUCCESS") {
                formik.resetForm();
                setPhoneValue('');
                toast.current.show({
                    life: globalConfig.toastDisplayTime,
                    severity: "success",
                    summary: t("toastSummary.success"),
                    detail: t("toast.contactSuccess"),
                });
                formik.resetForm();
                toast.current.show({ life: globalConfig.toastDisplayTime, severity: 'success', summary: t("toastSummary.success"), detail: t("login.signupSuccessful") })
                setTimeout(() => {
                    setDisableBtn(false);
                }, [3000])
            } else {
                const error = response.error;
                const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
                toast.current.show({
                    life: globalConfig.toastDisplayTime,
                    severity: error.severity,
                    summary: t("toastSummary.error"),
                    detail: t(`errorMsg.${errorMsg}`),
                });
                setDisableBtn(false);
            }
        }
        contact(requestObj, headers, dispatch, response);
    };

    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    return (
        <>
            <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="contact_form about_form"
            >
                <div className="grid mb-2">
                    <div className="col-12 lg:col-6 md:col-6">
                        <div className="flex flex-column">
                            <label htmlFor="firstName">{t("placeholder.firstName")}</label>
                            <span className="p-input-icon-right">
                                <PR.InputText id="firstName" name="firstName" placeholder={t('placeholder.firstName')} className="w-12" keyfilter={"alphanum"} value={formik.values.firstName} onChange={(e) => handleFirstNameChange(e, formik)} onBlur={formik.handleBlur} autoComplete="off" />
                                {formik.errors.firstName && formik.touched.firstName ? <div className='error-message'>{t(formik.errors.firstName)}</div> : ''}
                            </span>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 md:col-6">
                        <div className="flex flex-column">
                            <label htmlFor="lastName">{t("placeholder.lastName")}</label>
                            <span className="p-input-icon-right">
                                <PR.InputText id="lastName" name="lastName" placeholder={t('placeholder.userLastName')} className="w-12" keyfilter={"alphanum"} value={formik.values.lastName} onChange={(e) => handleLastNameChange(e, formik)} onBlur={formik.handleBlur} autoComplete="off" />
                                {formik.errors.lastName && formik.touched.lastName ? <div className='error-message'>{t(formik.errors.lastName)}</div> : ''}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="grid mb-2">
                    <div className="col-12 lg:col-6 md:col-6">
                        <div className="flex flex-column">
                            <label htmlFor="email">{t("placeholder.email")}</label>
                            <span className="p-input-icon-right">
                                <PR.InputText id="email" name="email" placeholder={t('placeholder.email')} className="w-12" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                                {formik.errors.email && formik.touched.email ? <div className='error-message'>{t(formik.errors.email)}</div> : ''}
                            </span>
                        </div>
                    </div>

                    <div className="col-12 lg:col-6 md:col-6">
                        <div className="flex flex-column">
                            <label htmlFor="phone">{t("placeholder.phone")}</label>
                            <CustomPhoneNumber formik={formik} t={t} setPhoneValue={setPhoneValue} setDisableBtn={setDisableBtn} />
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="flex flex-column">
                            <label htmlFor="body">{t("placeholder.messageLabel")}</label>
                            <span className="p-input-icon-right">
                                <PR.InputTextarea rows={2} cols={30} maxLength={500} autoResize className="w-12" id="body" name="body" placeholder={t('placeholder.askQuestion')} value={formik.values.body} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                                {formik.errors.body && formik.touched.body ? <div className='error-message'>{t(formik.errors.body)}</div> : ''}
                            </span>
                        </div>
                    </div>
                    <PR.Button
                        className="goto-plans-button p-button-rounded"
                        type="submit" label={t('faq.buttons.submit')}
                        disabled={!formik.isValid || disableBtn}
                    />
                </div>
            </form>
        </>
    );
}

export default ContactForm;