import React from 'react';
import { useTranslation } from 'react-i18next';

const PageLoading = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="overlay">
                <div className="loading-block">
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }}></i>
                    <p>{t("payment.paymentLoadingHeader")}</p>
                </div>
            </div>
        </>
    );
}
 
export default PageLoading;