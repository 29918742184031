import React, { useRef, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { esimIcon } from "../../assets/images";
import { useTranslation } from "react-i18next";
import BundlesList from "../../components/EsimBundles";
import "../home/Home.scss";
import "./GlobalEsims.scss";
import faq_en from "../../assets/data/faq_en.json";
import faq_es from "../../assets/data/faq_es.json";
import faq_pt from "../../assets/data/faq_pt.json";
import { useDispatch, useSelector } from "react-redux";
import getAssets from '../../assets/data/brandingAssets.json';
import { globalConfig } from "../../constants";
import SubHeader from "../../components/SubHeader";
import ContactForm from "../../components/ContactForm";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import { ScrollTo } from "../../utils/ScrollTo";

const GlobalEsims = ({renderFormState}) => {
  const { t, i18n } = useTranslation();
  const toast = useRef();
  const dispatch = useDispatch();
  const scrollToBundlesState = useSelector(state => state.scrollToBundles.scrollToBundlesState);
  const ref = useRef(null);
  const [faq, setFaq] = useState([]);
  const [openSignUp, setOpenSignUp] = useState(false);

  // const handleClickSignup = () => {
  //   setOpenSignUp("signup")
  // };

  useEffect(() => {
    localStorage.removeItem("PAYMENT-RESPONSE");
  }, []); 

  useEffect(() => {
    if (scrollToBundlesState) {
      ScrollTo(ref, -100);
      dispatch(scrollToBundlesActions.deleteSrollToBundles());
    }

    const defaultLang = i18n.language.split("-")[0];
    if (defaultLang === "en") {
      setFaq(faq_en);
    } else if (defaultLang === "es") {
      setFaq(faq_es);
    } else if (defaultLang === "pt") {
      setFaq(faq_pt);
    }
  }, [t, i18n, scrollToBundlesState, dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{globalConfig.domainName} | {t("globalEsims.title")}</title>
      </Helmet>
      {/* Header Section */}
      <SubHeader header={t("globalEsims.mainHeader")} subText={t("globalEsims.topHeader")} openSignUp={openSignUp} setOpenSignUp={setOpenSignUp} />


      {/* Plans Section */}
      <section className="plan-section" ref={ref}>
        <div className="grid align-items-center">
          <h2>{t("home.consfigurePlan")}</h2>
          <PR.Image src={esimIcon} className="esim-icon" alt="eSIM Icon" />
        </div>
        <div className="card">
          <div className="bundles-section">
            <BundlesList />
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <div className="grid">
          <div className="col-12 lg:col-6 md:col-6">
            <div className="option-box">
              <h2>{t("home.benefits.title")}</h2>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={getAssets.home.benifit_section.multi_network_connectivity}
                    alt="Multi-Network Connectivity"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{t("home.benefits.feature1")}</h3>
                  <p>{t("home.benefits.description1")}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={getAssets.home.benifit_section.fast_connections}
                    alt="Fast Connections"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{t("home.benefits.feature2")}</h3>
                  <p>{t("home.benefits.description2")}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={getAssets.home.benifit_section.support_24_7}
                    alt="Support 24x7"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{t("home.benefits.feature3")}</h3>
                  <p>{t("home.benefits.description3")}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={getAssets.home.benifit_section.easy_installation}
                    alt="Easy Installation"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{t("home.benefits.feature4")}</h3>
                  <p>{t("home.benefits.description4")}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={getAssets.home.benifit_section.best_pricing_plans}
                    alt="Best Pricing Plans"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{t("home.benefits.feature5")}</h3>
                  <p>{t("home.benefits.description5")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-6 md:col-6 text-center">
            <div className="option-box">
              <PR.Image
                className="benefits-img"
                src={getAssets.home.benifit_section.right_image}
                alt="Benefits"
              />
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-10 md:col-10 md:col-offset-1 lg:col-offset-1">
            <h2>{t("faq.mainHeading")}</h2>
            {faq &&
              faq.length > 0 &&
              faq.map((item, i) => (
                <PR.Accordion key={i}>
                  {item.QA.slice(0, 2).map((qa, j) => (
                    <PR.AccordionTab header={qa.question} key={j}>
                      {qa.answer.map((an, h) => (
                        <div key={h}>
                          {an.content_heading && (
                            <p><strong>{an.content_heading}</strong></p>
                          )}
                          {an.content && (
                            <p>{an.content}</p>
                          )}
                          {an.olist?.length > 0 && (
                            <ol>
                              {an.olist.map((anl, nh) => (
                                <li key={nh}>{anl}</li>
                              ))}
                            </ol>
                          )}
                          {an.ulist?.length > 0 && (
                            <ul>
                              {an.ulist.map((anl, nh) => (
                                <li key={nh}>{anl}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </PR.AccordionTab>
                  ))}
                </PR.Accordion>
              ))}
          </div>
        </div>
        <div className="text-center faq-custom-margin">
            <Link to="/faq" className="view-all-button">{t("faq.checkoutFaqs")}</Link>
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact-section contact-form-section home-contact-section">
        <div className="grid header-text">
          <div className="col-12 text-center">
            <h1>{t("contact.contactSupportTeam")}</h1>
            <p>{t("contact.helperText1")}</p>
          </div>
        </div>
        <div className="grid header-text">
            <div className="col-12">
              <div className="form-box">
                <ContactForm formType={"globalEsims"} toast={toast} />
              </div>
            </div>
        </div>
      </section>    
      <PR.Toast ref={toast} position="top-right" />

    </HelmetProvider>
  );
};

export default GlobalEsims;