import React, { useRef, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as PR from '../prime-modules/index';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { globalConfig } from "../constants";
import { verifyemail } from "../services/api";
import { authActions } from "../store/auth";

const VerifyEmail = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const idPath = useSelector(state => state.auth.idPath);
  const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
  const isLoggedIn = useSelector(state =>  state.auth.isLoggedIn);
  const location = useLocation();
  const toast = useRef();
  const [disableBtn, setDisableBtn] = useState(false);
  const headers = useMemo(() => {
      return {afid: affiliateTrackingData};
  }, [affiliateTrackingData]);

  useEffect(() => {
    if (!idPath.userId) {
      toast.current.show({ life:globalConfig.toastDisplayTime, severity: 'error', summary:t('toastSummary.error'), detail: t('verifyEmail.userMissing') })
    }
    
  }, [t, location, idPath.userId])

  const formInitialValues = {
    otp: '',
  };

  const validationSchema = () => {
    return Yup.object().shape({
      otp: Yup.string().trim()
        .required('validation.otp')
        .min(6, 'validation.otpMaxError')
        .max(6, 'validation.otpMaxError')
        .matches(/^[0-9\b]+$/, 'validation.otpTypeError')
    });
  }

  const submitHandler = (formData) => {
    setDisableBtn(true);
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        toast.current.show({ life:globalConfig.toastDisplayTime, severity: 'success', summary:t('toastSummary.success'), detail: t('verifyEmail.successMessage') })
        formik.resetForm();
        setTimeout(() => {
          dispatch(authActions.deleteStoreIdPath());
          props.renderFormState(!isLoggedIn, (isLoggedIn ? "" : "login"))
        }, 2000);
      } else {
        const error = response.error;
        const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
        toast.current.show({ life:globalConfig.toastDisplayTime, severity: error.severity, summary:t('toastSummary.error'), detail: t(`errorMsg.${errorMsg}`) })
      }
      setDisableBtn(false);
    }

    const otpVal = formData.otp.trim();
    const otpRequest = {
      userId: +idPath.userId,
      code: otpVal
    }

    const apiUrl = (idPath.pathName === "verify-email") ? 'verifyemail' : 'updateemail';
    verifyemail(otpRequest, apiUrl, headers, dispatch, getResponse)
  }
  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationSchema,
    onSubmit: submitHandler
  });

  return (
    <>
      <div className="form-section forgot-password-section">
          <div className="form">
              <h3>{t('verifyEmail.header')}</h3>
              <p>{t('verifyEmail.subText')}</p>
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="input-field">
                    <PR.InputText id="otp" name="otp" placeholder={t('placeholder.otp')} className="w-12" value={formik.values.otp} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                    {formik.errors.otp && formik.touched.otp
                      ? <div className='error-message'>{t(formik.errors.otp)}</div>
                      : ''
                    }
                  </div> 
                  <PR.Button label={t('verifyEmail.submitButton')} type='submit' disabled={disableBtn} className="login-btn signup-btn" />
              </form>
          </div>
      </div>
      <PR.Toast ref={toast} position="top-right" />
    </>
  );
};

export default VerifyEmail;