import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { activationIcon, dashboardIcon, investmentIcon, businessDealImg, stepsImg1, stepsImg2, stepsImg3, airlineIcon, travelIcon, ukTravelIcon } from "../../assets/images";
import { useTranslation } from "react-i18next";
import HeaderMenu from "../../components/layout/HeaderMenu";
import "../home/Home.scss";
import "../affiliate/Affiliate.scss";
import { useDispatch, useSelector } from "react-redux";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import { envConfig } from "../../constants";
import { globalConfig } from '../../constants';

const Affiliate = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const scrollToBundlesState = useSelector(state => state.scrollToBundles.scrollToBundlesState);

  useEffect(() => {
    if(scrollToBundlesState) {
      dispatch(scrollToBundlesActions.desleteSrollToBundles());
    }
  }, [t, i18n, scrollToBundlesState, dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{globalConfig.domainName} | {t("affiliate.title")}</title>
      </Helmet>

      {/* Header Section */}
      <section className="header-section affiliate-header-section">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid header-text">
          <div className="col-12 lg:col-8 md:col-8">
            <div className="header-text-box">
              <h1>{t("affiliate.topHeader")}</h1>
              <p>{t("affiliate.topHeaderDescription")}</p>
              <Link
                to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'}
                target='_blank'
                className="go-to-home"><b>{t("header.signup")}</b>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Options Section */}
      <section className="options-section affiliate-options">
        <div className="grid">
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={activationIcon} alt="Instant Activation" />
              <h2>{t("affiliate.infoCard.step1")}</h2>
              <p>{t("affiliate.infoCard.description1")}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={dashboardIcon} alt="Attractive Rates" />
              <h2>{t("affiliate.infoCard.step2")}</h2>
              <p>{t("affiliate.infoCard.description2")}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={investmentIcon} alt="Global Coverage" />
              <h2>{t("affiliate.infoCard.step3")}</h2>
              <p>{t("affiliate.infoCard.description3")}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Affiliate Program Section */}
      <section className="offer-section affiliate-program-section">
        <div className="grid column-reverse">
          <div className="col-12 lg:col-6 md:col-6 text-center">
            <div className="option-box">
              <PR.Image
                className="affiliate-program-image"
                src={businessDealImg}
                alt="Connectivity"
              />
            </div>
          </div>
          <div className="col-12 lg:col-6 md:col-6">
            <div className="option-box">
                <h2>{t("affiliate.affiliateProgramText")}</h2>
                <p>{t("affiliate.affiliateProgramDescription")}</p>
                <Link
                to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'}
                target='_blank'
                className="choose-plans-button"><b>{t("affiliate.getStartedBtnText")}</b>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Steps Section */}
      <section className="plan-section steps-section">
        <div className="align-items-center">
          <h2>{t("affiliate.stepsSection.stepsHeading")}</h2>
          <p>{t("affiliate.stepsSection.stepsDescription1")}</p>
          <div className="card">
              <div className="grid">
                  <div className="col-12 lg:col-4 md:col-4">
                    <div className="relative">
                      <div className="steps-box">
                        <div className="steps-icon">
                          <PR.Image src={stepsImg1} alt="Step One" /> 
                        </div>
                        <h3>
                          <p>01</p>
                          {t("affiliate.stepsSection.step1")}
                        </h3>
                      </div>
                      <div className="stepsnumber"></div>
                    </div>
                  </div>
                  <div className="col-12 lg:col-4 md:col-4">
                    <div className="relative">
                      <div className="steps-box">
                        <div className="steps-icon secondary-color">
                          <PR.Image src={stepsImg2} alt="Step Two" />
                        </div>
                        <h3>
                          <p className="secondary-color">02</p>
                          {t("affiliate.stepsSection.step2")}
                        </h3>
                      </div>
                        <div className="stepsnumber secondary-color"></div>
                    </div>
                  </div>
                  <div className="col-12 lg:col-4 md:col-4">
                      <div className="relative">
                        <div className="steps-box">
                          <div className="steps-icon">
                            <PR.Image src={stepsImg3} alt="Step Three" />
                          </div>
                          <h3>
                            <p>03</p>
                            {t("affiliate.stepsSection.step3")}
                          </h3>
                        </div>
                        <div className="stepsnumber"></div>
                      </div>
                  </div>
              </div>
          </div>
          <p>{t("affiliate.stepsSection.stepsDescription2")}</p>
        </div>
      </section>

      <section className="plan-section sales-info-section">
        <div className="align-items-center">
          <h2>{t("affiliate.salesInfoSection.Heading")}</h2>
          <div className="card info-box">
              <div className="div-row first-row">
                 <div className="left">
                    <ul className="flex align-items-center">
                      <li>
                        <PR.Image
                          src={airlineIcon}
                          alt="Connectivity"
                        />
                      </li>
                      <li>
                        <h3>{t("affiliate.salesInfoSection.first.salesAffiliateHeading")}</h3>
                        <p>{t("affiliate.salesInfoSection.first.salesAffiliateSubHeading")}</p>
                      </li>
                    </ul>
                 </div>
                 <div className="right">
                    <p>58,000 {globalConfig.domainName} {t("affiliate.salesInfoSection.sales")}</p>
                    <p><span>$88,000 / {t("affiliate.salesInfoSection.month")}</span></p>
                 </div>
              </div>
              <div className="div-row">
                 <div className="left">
                    <ul className="flex align-items-center">
                      <li>
                        <PR.Image
                          src={travelIcon}
                          alt="Connectivity"
                        />
                      </li>
                      <li>
                        <h3>{t("affiliate.salesInfoSection.second.salesAffiliateHeading")}</h3>
                        <p>{t("affiliate.salesInfoSection.second.salesAffiliateSubHeading")}</p>
                      </li>
                    </ul>
                 </div>
                 <div className="right">
                    <p>20,000 {globalConfig.domainName} {t("affiliate.salesInfoSection.sales")}</p>
                    <p><span>$60,000 / {t("affiliate.salesInfoSection.month")}</span></p>
                 </div>
              </div>
              <div className="div-row">
                 <div className="left">
                    <ul className="flex align-items-center">
                      <li>
                        <PR.Image
                          src={ukTravelIcon}
                          alt="Connectivity"
                        />
                      </li>
                      <li>
                        <h3>{t("affiliate.salesInfoSection.third.salesAffiliateHeading")}</h3>
                        <p>{t("affiliate.salesInfoSection.third.salesAffiliateSubHeading")}</p>
                      </li>
                    </ul>
                 </div>
                 <div className="right">
                    <p>350 {globalConfig.domainName} {t("affiliate.salesInfoSection.sales")}</p>
                    <p><span>$950 / {t("affiliate.salesInfoSection.month")}</span></p>
                 </div>
              </div>
              <p className="text-center info_p">{t("affiliate.salesInfoSection.bottomContent")}</p>
          </div>
        </div>
      </section>

      {/* Partners Section */}
      <section className="partners-section getting-started-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-7 md:col-7">
            <div className="partners-text-box getting-started-box">
              <div>
                <h2>{t("affiliate.gettingStartedSection.heading")}</h2>
                <p>{t("affiliate.gettingStartedSection.subHeading1")}<br/> {t("affiliate.gettingStartedSection.subHeading2")}</p>
                <ul>
                  <li><span className="number">1</span></li>
                  <li>
                    <h3>{t("affiliate.gettingStartedSection.stepHeading1")}</h3>
                    <p>{t("affiliate.gettingStartedSection.stepDescription1")}</p>
                  </li>
                </ul>
                <ul>
                  <li><span className="number">2</span></li>
                  <li>
                    <h3>{t("affiliate.gettingStartedSection.stepHeading2")}</h3>
                    <p>{t("affiliate.gettingStartedSection.stepDescription2")}</p>
                  </li>
                </ul>
                <ul>
                  <li><span className="number">3</span></li>
                  <li>
                    <h3>{t("affiliate.gettingStartedSection.stepHeading3")}</h3>
                    <p>{t("affiliate.gettingStartedSection.stepDescription3")}</p>
                    <Link className="view-all-button" to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'} target="_blank">{t("affiliate.gettingStartedSection.buttonText")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-5 md:col-5">
            <div className="getting-started-right-box">
              <div className="info">
                <h2>{t("affiliate.gettingStartedSection.headingRight")}</h2>
                <Link className="view-all-button btn-white" to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'} target="_blank">{t("affiliate.getStartedBtnText")}</Link>
                <Link className="view-all-button faq-btn" to='/faq'>{t("faq.checkoutFaqs")}</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </HelmetProvider>
  );
};

export default Affiliate;