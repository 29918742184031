import { useTranslation } from "react-i18next";
import * as PR from "../../prime-modules/index";
import { useState } from "react";


const RoamingCountries = ({roaming}) => {
    const { t } = useTranslation();
    const [displayRoaming, setDisplayRoaming] = useState(false);

    const distributeColumns = (items, numColumns) => {
        const totalItems = items && items.length;
        const itemsPerColumn = Math.floor(totalItems / numColumns);
        let remainingItems = totalItems % numColumns;
    
        const columns = Array.from({ length: numColumns }, () => []);
    
        let itemIndex = 0;
        for (let columnIndex = 0; columnIndex < numColumns; columnIndex++) {
          const numItems = itemsPerColumn + (remainingItems > 0 ? 1 : 0);
          remainingItems -= 1;
    
          // Add the items to the current column
          columns[columnIndex] =
            items && items.slice(itemIndex, itemIndex + numItems);
    
          itemIndex += numItems;
        }
        return columns;
      };

    const countriesList = roaming?.length ? roaming.map((obj) => obj.country.name).sort((a, b) => a.localeCompare(b))  : [];
    const columns = distributeColumns(countriesList, 4);
    return (
      roaming &&
      (roaming.length === 0 ? (
        <span className="roamingData">{t("home.esim.no")}</span>
      ) : (
        <span className="roamingData">
          {t("home.esim.yes")} ({roaming.length} {t("home.esim.countries")})
          <i
            className="roaming-tooltip pi pi-info-circle info-btn"
            title="View Roaming Countries"
            onClick={() => setDisplayRoaming(true)}
          ></i>
          <PR.Dialog
            header={t("home.esim.roamingCountries")}
            className="roamingColumnsSection bundle-dialog"
            visible={displayRoaming}
            blockScroll={true}
            draggable={false}
            onHide={() => setDisplayRoaming(false)}
            style={{ width: "60vw" }}
            resizable={false}
          >
            <div className="grid roamingColumnsDialog">
              {columns &&
                columns.map((column, columnIndex) => (
                  <div key={columnIndex}>
                    <ul key={columnIndex}>
                      {column.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          {item && item.split("(")[0].trim()}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </PR.Dialog>
        </span>
      ))
    );
}
 
export default RoamingCountries;