import React, { useState, useRef, useMemo, useEffect } from "react";
import * as PR from "../../prime-modules/index";
import { updateUserProfile } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { authActions } from "../../store/auth";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { globalConfig } from "../../constants";
import { cartFilterActions } from "../../store/cart";
import {
  handleFirstNameChange,
  handleLastNameChange,
  trimFormData,
  displayDefaultPhone
} from "../../utils/reuse";
import CustomPhoneNumber from "../../components/CustomPhoneNumber";
import { allCountriesActions } from "../../store/allCountries";
import { parsePhoneNumber } from 'react-phone-number-input';
import { getPhoneObj } from "../../genericFunctions/GetAllCountries";

const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef();
  const isAuth = useSelector(state => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.sessionData);
  const getAllCountries = useSelector((state) => state.allCountries.allCountries);
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { sessionid: userData.sessionId, afid: affiliateTrackingData };
  }, [userData.sessionId, affiliateTrackingData]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');
  const getDefaultCallingCode = useSelector((state) => state.allCountries.defaultCallingCode);
  const [phoneError, setPhoneError] = useState("")

  const [initialValues] = useState({
    firstName: userData.firstName ?? "",
    lastName: userData.lastName ?? "",
    email: userData.email ?? "",
    phone: displayDefaultPhone(isAuth, userData, getDefaultCallingCode)
  });

  useEffect(() => {
    if (userData.signupType && userData.signupType !== "") {
      setDisableEmail(true);
    }
  }, [userData, disableEmail]);

  const validationSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .required("validation.firstName")
        .min(3, "validation.firstNameMinMaxError")
        .max(20, "validation.firstNameMinMaxError"),
      lastName: Yup.string()
        .trim()
        .required("validation.lastName")
        .min(3, "validation.lastNameMinMaxError")
        .max(20, "validation.lastNameMinMaxError"),
      email: Yup.string()
        .trim()
        .email("validation.validEmail")
        .required("validation.email"),
    });
  };

  const handleSubmit = (formData) => {
    setDisableBtn(true);
    const trimmedFormData = trimFormData(formData);

    const phone = getPhoneObj(phoneValue, isAuth, userData, formData);;
    const emailVal = trimmedFormData.email;

    const requestObj = {
      userId: userData.userId ?? 0,
      firstName: trimmedFormData.firstName,
      lastName: trimmedFormData.lastName,
      email: emailVal,
      phone: phone,
    };
    updateUserProfile(requestObj, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const filterCode = getAllCountries.filter(country => country.callingCode === phone.callingCode);
        dispatch(allCountriesActions.setPhoneCountry(filterCode[0]));
        toast.current.show({
          life: globalConfig.toastDisplayTime,
          severity: "success",
          summary: t("toastSummary.success"),
          detail: t("toast.userSuccessUpdate"),
        });
        setTimeout(() => {
          setDisableBtn(false);
        }, [3000])
        if (userData.email !== emailVal) {
          setTimeout(() => {
            dispatch(authActions.onLogout());
            dispatch(cartFilterActions.removeRandomIdInPayment());
            navigate("/login");
          }, [2000]);
        } else {
          requestObj.sessionId = userData.sessionId;
          requestObj.signupType = userData.signupType;
          dispatch(authActions.onLogin(requestObj));
        }
      } else {
        const error = response.error;
        const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
        toast.current.show({
          life: globalConfig.toastDisplayTime,
          severity: error.severity,
          summary: t("toastSummary.error"),
          detail: t(`errorMsg.${errorMsg}`),
        });
      }
      setDisableBtn(false);
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    let isValidPhone = parsePhoneNumber(formik.values.phone);
    if(!isValidPhone?.nationalNumber) {
        setPhoneError(t("validation.phone"))
    } else if(isValidPhone?.nationalNumber.length <=6 || isValidPhone?.nationalNumber === undefined ) {
        setPhoneError(t("validation.phoneMinMaxError"))
    } else {setPhoneError("")}
}, [formik.values.phone, t])

  return (
    <>
      <div className="profile-section">
        <div className="esim-box">
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <div className="grid mb-3">
              <div className="col-12 lg:col-6 md:col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="firstName">{t("placeholder.firstName")}</label>
                  <span className="p-input-icon-right">
                    <i className="pi pi-user" />
                    <PR.InputText
                      id="firstName"
                      name="firstName"
                      placeholder={t("placeholder.firstName")}
                      className="w-12"
                      value={formik.values.firstName}
                      onChange={(e) => handleFirstNameChange(e, formik)}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                      keyfilter={"alphanum"}
                    />
                  </span>
                  {formik.errors.firstName && formik.touched.firstName &&
                    <div className="error-message"> {t(formik.errors.firstName)}</div>
                  }
                </div>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="lastName">{t("placeholder.lastName")}</label>
                  <span className="p-input-icon-right">
                    <i className="pi pi-user" />
                    <PR.InputText
                      id="lastName"
                      name="lastName"
                      placeholder={t("placeholder.lastName")}
                      className="w-12"
                      value={formik.values.lastName}
                      onChange={(e) => handleLastNameChange(e, formik)}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                      keyfilter={"alphanum"}
                    />
                  </span>
                  {formik.errors.lastName && formik.touched.lastName &&
                    <div className="error-message">{t(formik.errors.lastName)} </div>
                  }
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col-12 lg:col-6 md:col-6">
                <div className="flex flex-column gap-2">
                  <label htmlFor="email">{t("placeholder.email")}</label>
                  <span className="p-input-icon-right">
                    <i className="pi pi-envelope" />
                    <PR.InputText
                      id="email"
                      name="email"
                      placeholder={t("placeholder.email")}
                      className="w-12"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                      disabled={disableEmail}
                    />
                  </span>
                  {formik.errors.email && formik.touched.email &&
                    <div className="error-message">{t(formik.errors.email)}</div>
                  }
                </div>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <div className="flex flex-column gap-2">
                    <label htmlFor="phone">{t("placeholder.phone")}</label>
                    <CustomPhoneNumber formik={formik} t={t} setPhoneValue={setPhoneValue} setDisableBtn={setDisableBtn} phoneError={phoneError}/>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col-12">
                <PR.Button
                  label={t("myAccount.buttons.saveChanges")}
                  type="submit"
                  disabled={!formik.isValid || disableBtn || phoneError}
                  className="update-button"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <PR.Toast ref={toast} position="top-right" />
    </>
  );
};

export default Profile;
