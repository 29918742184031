import React, { useState } from 'react'
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { useTranslation } from 'react-i18next';
import { globalConfig } from '../../constants';
import getAssets from "../../assets/data/brandingAssets.json";

const Footer = () => {
  const { t } = useTranslation();
  const [year] = useState(new Date().getFullYear());

  return (
    <>
      <footer>
        <div className='grid grid-nogutter align-items-center justify-content-between copyrights-div'>
        <div className='right'>
            <PR.Image className="logo" src={getAssets.logo.logo_dark} alt="logo" width='145'/>
          </div>
          <p>&copy;{year} <Link to="/">{globalConfig.domainName}.</Link> {t("footer.copyright")}</p>
          
        </div>
        <PR.ScrollTop className='scroll-to-top' icon="pi pi-arrow-up" />
      </footer>
    </>
  )
}
export default Footer