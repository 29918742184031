import React, { useRef } from "react";
import "../about-us/AboutUs.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { globalConfig } from "../../constants";
import SubHeader from "../../components/SubHeader";
import getAssets from '../../assets/data/brandingAssets.json';

const AboutUs = () => {
  const { t } = useTranslation();
  const toast = useRef();

  return (
     <HelmetProvider> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>{globalConfig.domainName} | {t("aboutUs.title")}</title>
      </Helmet>

      {/* Header Section */}
      <div className="about-page">
        <SubHeader header={t("aboutUs.title")} subText={t("aboutUs.exploreText")}/>
      </div>
      <PR.Toast ref={toast} position='top-right' />
      {getAssets.about_us.aboutus_section_display && <section className="about-blocks-section">
        <div className="custom-container">
          <div className="grid align-items-center custom-margin">
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={getAssets.about_us.unlock_world} className="about-img" alt="Unlock a World of Seamless Connectivity"/>
              </div>
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{t("aboutUs.unlockText")}</h2>
                <p>{t("aboutUs.description1")}</p>
              </div>
          </div>
          <div className="grid align-items-center custom-margin column-reverse">
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{t("aboutUs.instantActivation")}</h2>
                <p>{t("aboutUs.description2")}</p>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={getAssets.about_us.instant_activation} className="about-img" alt="Instant Activation"/>
              </div>
          </div>
          <div className="grid align-items-center custom-margin">
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={getAssets.about_us.global_coverage} className="about-img" alt="Global Coverage"/>
              </div>
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{t("aboutUs.globalCoverage")}</h2>
                <p>{t("aboutUs.description3")}</p>
              </div>
          </div>
          <div className="grid align-items-center custom-margin column-reverse">
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{t("aboutUs.attractiveRates")}</h2>
                <p>{t("aboutUs.description4")}</p>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={getAssets.about_us.attractive_rates} className="about-img" alt="Attractive Rates"/>
              </div>
          </div>
        </div>
      </section>}
    </HelmetProvider>
  );
};

export default AboutUs;