import {useEffect} from "react";
import {useStripe, useElements} from '@stripe/react-stripe-js';
import { globalConfig } from "../../constants";

const StripeElements = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (props.orderAmount) {

            if (!stripe || !elements) {
                return;
            }

            const orderTotal = parseInt(props.orderAmount * 100);

            const stripeRequestData = {
                country: globalConfig.paymentDefaultCountry,
                currency: globalConfig.paymentDefaultCurrency,
                total: {
                    label: globalConfig.paymentDefaultLabel,
                    amount: orderTotal,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            }

            const pr = stripe.paymentRequest(stripeRequestData);

            pr.canMakePayment().then((result) => {
                if (result) {
                    if (result.applePay) {
                        props.setApplePayDisplay(result.applePay);
                    }

                    if (result.googlePay) {
                        props.setGooglePayDisplay(result.googlePay);
                    }
                }
            });
        }
    }, [props, stripe, elements])
};

export default StripeElements;