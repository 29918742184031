import React, { useEffect } from 'react';
// **** Main CSS **** //
import './assets/scss/main.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import AboutUs from './pages/about-us/AboutUs';
import Partners from './pages/partners/Partners';
import MyAccount from './pages/my-account/MyAccount';
import RedirectVerifyEmail from './components/RedirectVerifyEmail';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import CookiePolicy from './pages/cookie-policy/CookiePolicy';
import PageNotFound from './pages/page-not-found/PageNotFound';
import Faq from './pages/faq/Faq';
import Maintenance from './pages/maintenance/Maintenance';
import CompatibleDevices from './pages/compatible-devices/CompatibleDevices';
import { useSelector } from 'react-redux';
import ScrollToTop from './components/layout/ScrollToTop';
import Footer from './components/layout/Footer';
import BundleDetails from './pages/bundle-details/BundleDetails';
import Paypal from './pages/web-paypal/Paypal';
import Affiliate from './pages/affiliate/Affiliate';
import getAssets from './assets/data/brandingAssets.json';
import GlobalEsims from './pages/global-esims/GlobalEsims';
import PaymentSummary from './components/payment/PaymentSummary';

function App() {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const siteMaintenance = useSelector(state => state.sitemaintenance.sitemaintenanceInfo);
  const isUnderMaintenance = (siteMaintenance === 'true') ? true : false;

  useEffect(() => {
    document.documentElement.style.setProperty('--font-base', getAssets.fonts['font-base']);
    document.documentElement.style.setProperty('--font-secondary', getAssets.fonts['font-secondary']);
    document.documentElement.style.setProperty('--general_font_size', getAssets.fonts['general_font_size']);

    document.documentElement.style.setProperty('--primary', getAssets.styles['primary']);
    document.documentElement.style.setProperty('--secondary', getAssets.styles['secondary']);
    document.documentElement.style.setProperty('--sky-light', getAssets.styles['sky-light']);
    document.documentElement.style.setProperty('--dark-blue', getAssets.styles['dark-blue']);
    document.documentElement.style.setProperty('--black', getAssets.styles['black']);
    document.documentElement.style.setProperty('--white', getAssets.styles['white']);
    document.documentElement.style.setProperty('--light-gray', getAssets.styles['light-gray']);
    document.documentElement.style.setProperty('--pastel-white', getAssets.styles['pastel-white']);
    document.documentElement.style.setProperty('--input-border', getAssets.styles['input-border']);
    document.documentElement.style.setProperty('--form', getAssets.styles['form']);
    document.documentElement.style.setProperty('--menu', getAssets.styles['menu']);
    document.documentElement.style.setProperty('--theme-gray', getAssets.styles['theme-gray']);
    document.documentElement.style.setProperty('--sub-menu', getAssets.styles['sub-menu']);
    document.documentElement.style.setProperty('--benifit-icons-bg', getAssets.styles['benifit-icons-bg']);

    document.documentElement.style.setProperty('--logo-right', getAssets.styles['logo-right']);
    document.documentElement.style.setProperty('--logo-left', getAssets.styles['logo-left']);
  });

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <>
            {isUnderMaintenance
              ? <Route index element={<Maintenance />} />
              : <>
                <Route index exact path='/' element={< Home />} />
                <Route path='/about-us' element={< AboutUs />} />
                <Route path='/partners' element={getAssets.screens.partners ? < Partners /> : <Navigate to="/" />} />
                <Route path='/my-account' element={isLoggedIn ? < MyAccount /> : <Navigate to="/" />} />
                <Route path='/update-email' element={<RedirectVerifyEmail />} />
                <Route path='/verify-email' element={<RedirectVerifyEmail />} />
                <Route path='/reset-password' element={<RedirectVerifyEmail />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/bundles/:id' element={<BundleDetails />} />
                <Route path='/paypal' element={<Paypal />} />
                <Route path='/compatible-devices' element={<CompatibleDevices />} />
                <Route path='/affiliate' element={getAssets.screens.affiliate ? <Affiliate /> : <Navigate to="/" />} />
                <Route path='/global-esims' element={<GlobalEsims />} />
                <Route path='/paymentresponse' element={<PaymentSummary />} />
                <Route path='*' element={<PageNotFound />} />
              </>
            }
          </>
        </Routes>
        {window.location.pathname !== '/paypal' && <Footer />}
      </Router>
    </>
  );
}

export default App;