import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cart';
import countryReducer from './country';
import authReducer from './auth';
import sitemaintenanceReducer from './sitemaintenance';
import esimReducer from './esim';
import stateKeyReducer from './stateKey';
import affiliateTrackingReducer from './affiliateTracking';
import scrollToBundles from './scrollToBundles';
import allCountriesReducer from './allCountries';
import paymentReducer from './payment';

export const store = configureStore({
    reducer: { auth: authReducer, cart: cartReducer, country: countryReducer, sitemaintenance: sitemaintenanceReducer, esim: esimReducer, affiliateTracking: affiliateTrackingReducer,stateKey: stateKeyReducer, scrollToBundles:scrollToBundles, allCountries:  allCountriesReducer, payment: paymentReducer }
});

export default store;