import React, { useMemo, useRef, useState, useEffect } from "react";
import "../partners/Partners.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { partnerImage } from "../../assets/images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { globalConfig } from "../../constants";
import SubHeader from "../../components/SubHeader";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { contact } from "../../services/api";
import { handleFirstNameChange, handleLastNameChange, handleTitleChange, trimFormData, handleCompanyNameChange } from "../../utils/reuse";
import { fetchCountryInfo } from '../../genericFunctions/GetAllCountries';
import { ScrollTo } from "../../utils/ScrollTo";

const Partners = () => {
  const { t } = useTranslation();
  const toast = useRef();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [disableBtn, setDisableBtn] = useState(false);
  const [country, setCountry] = useState([]);
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.sessionData);
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );

  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);
  const formInitialValues = {
    firstName: isAuth ? userData?.firstName : "",
    lastName: isAuth ? userData?.lastName : "",
    email: isAuth ? userData?.email : "",
    body: "",
    company: "",
    title: "",
    type: "partners",
    country: null
  };

  const validationSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .required("validation.firstName")
        .min(3, "validation.firstNameMinMaxError")
        .max(20, "validation.firstNameMinMaxError"),
      lastName: Yup.string()
        .trim()
        .min(3, "validation.lastNameMinMaxError")
        .max(20, "validation.lastNameMinMaxError"),
      email: Yup.string()
        .trim()
        .email("validation.validEmail")
        .required("validation.email"),
      country: Yup.string()
        .trim()
        .nullable()
        .required("validation.country"),
      title: Yup.string()
        .trim()
        .min(3, "validation.titleNameMinMaxError")
        .max(50, "validation.titleNameMinMaxError")
        .required("validation.title"),
      company: Yup.string()
        .trim()
        .min(3, "validation.companyNameMinMaxError")
        .max(50, "validation.companyNameMinMaxError")
        .required("validation.company"),
      body: Yup.string().trim().required("validation.contactMessage"),
    });
  };

  const handleSubmit = (formData) => {
    setDisableBtn(true);
    const contactResponse = (response) => {
      if (response.result === "SUCCESS") {
        formik.resetForm();
        toast.current.show({
          life: globalConfig.toastDisplayTime,
          severity: "success",
          summary: t("toastSummary.success"),
          detail: t("toast.contactSuccess"),
        });
      } else {
        const error = response.error;
        const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
        toast.current.show({
          life: globalConfig.toastDisplayTime,
          severity: error.severity,
          summary: t("toastSummary.error"),
          detail: t(`errorMsg.${errorMsg}`),
        });
      }
      setDisableBtn(false);
    };
    const trimmedFormData = trimFormData(formData);
    contact(trimmedFormData, headers, dispatch, contactResponse);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleCountryBlur = () => {
    formik.setFieldTouched('country', true);
  };

  const handleClick = () => {
    ScrollTo(ref, 0);
  };

  useEffect(() => {
    const fetchCountryData = async () => {
      const params = {
        countries: '',
        info: ''
      }
      await fetchCountryInfo(params, dispatch, setCountry, toast, t);
    };

    fetchCountryData();
  }, [dispatch, setCountry, toast, t]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{globalConfig.domainName} | {t("partner.title")}</title>
      </Helmet>

      {/* Header Section */}
      <SubHeader header={t("partner.description1")} subText={t("partner.description2")} />
      <PR.Toast ref={toast} position="top-right" />

      <section className="partners-section custom-margin">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-7 md:col-7">
            <div className="partners-text-box flex align-items-center justify-content-center">
              <div>
                <h2>{t("home.partner.title")}</h2>
                <p>{t("home.partner.description")}</p>
                <Link className="view-all-button" onClick={handleClick}>{t("home.partner.button")}</Link>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-5 md:col-5 text-center">
            <div className="option-box">
              <PR.Image
                className="benefits-img"
                src={partnerImage}
                alt="Benefits"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form-section partners-contact-form" ref={ref}>
        <div className="grid header-text">
          <div className="col-12 text-center">
            <h2>{t("partner.contactFormHeading")}</h2>
          </div>
        </div>
        <div className="grid header-text">
          <div className="col-12">
            <div className="form-box">
              <form className="contact_form" onSubmit={formik.handleSubmit}
                autoComplete="off">
                <div className="grid mb-3">
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="firstName">{t("placeholder.firstName")}</label>
                      <span className="p-input-icon-right">
                        <PR.InputText id="firstName" name="firstName" placeholder={t('placeholder.firstName')} className="w-12" keyfilter={"alphanum"} value={formik.values.firstName} onChange={(e) => handleFirstNameChange(e, formik)} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.firstName && formik.touched.firstName ? <div className='error-message'>{t(formik.errors.firstName)}</div> : ''}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="lastName">{t("placeholder.lastName")}</label>
                      <PR.InputText id="lastName" name="lastName" placeholder={t('placeholder.userLastName')} className="w-12" keyfilter={"alphanum"} value={formik.values.lastName} onChange={(e) => handleLastNameChange(e, formik)} onBlur={formik.handleBlur} autoComplete="off" />
                      {formik.errors.lastName && formik.touched.lastName ? <div className='error-message'>{t(formik.errors.lastName)}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="grid mb-3">
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="company">{t('placeholder.company')}</label>
                      <span className="p-input-icon-right">
                        <PR.InputText id="company" name="company" placeholder={t('placeholder.companyName')} className="w-12" keyfilter={"alphanum"} value={formik.values.company} onChange={(e) => handleCompanyNameChange(e, formik)} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.company && formik.touched.company ? <div className='error-message'>{t(formik.errors.company)}</div> : ''}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="title">{t('placeholder.title')}</label>
                      <span className="p-input-icon-right">
                        <PR.InputText id="title" name="title" placeholder={t('placeholder.enterTitle')} className="w-12" value={formik.values.title} onChange={(e) => handleTitleChange(e, formik)} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.title && formik.touched.title ? <div className='error-message'>{t(formik.errors.title)}</div> : ''}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid mb-3">
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="country">{t('placeholder.country')}</label>
                      <span className="p-input-icon-right">
                        <PR.Dropdown
                          id="country"
                          name="country"
                          value={formik.values.country}
                          options={country}
                          onChange={formik.handleChange}
                          onHide={handleCountryBlur}
                          resetFilterOnHide
                          optionLabel="label"
                          optionValue="value"
                          showClear placeholder={t('placeholder.selectCountry')}
                          style={{ 'width': '100%' }}
                          filter
                          filterBy="label"
                        />
                        {formik.errors.country && formik.touched.country ? <div className='error-message'>{t(formik.errors.country)}</div> : ''}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 lg:col-6 md:col-6">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="email">{t('placeholder.businessEmail')}</label>
                      <span className="p-input-icon-right">
                        <PR.InputText id="email" name="email" placeholder={t('placeholder.emailAddress')} className="w-12" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.email && formik.touched.email ? <div className='error-message'>{t(formik.errors.email)}</div> : ''}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div className="col-12">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="body">{t("placeholder.messageLabel")}</label>
                      <span className="p-input-icon-right">
                        <PR.InputTextarea rows={4} cols={30} maxLength={500} autoResize className="w-12" id="body" name="body" placeholder={t('placeholder.message')} value={formik.values.body} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.body && formik.touched.body ? <div className='error-message'>{t(formik.errors.body)}</div> : ''}
                      </span>
                    </div>
                  </div>
                  <PR.Button className="contact-button p-button-rounded" type="submit" label={t('faq.buttons.submit')} disabled={!formik.isValid || disableBtn} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Partners;