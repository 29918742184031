import { Link } from "react-router-dom";
import { globalConfig } from "../constants";
import { useTranslation } from "react-i18next";

const SupportMailsSection = () => {
  const { t } = useTranslation();
  return (
    <section className="support-mails-section">
    <div className="custom-container">
        <div className="grid grid-nogutter contact-info-block">
            <div className="col-12 lg:col-4 md:col-4">
            <h3>{t("header.contactInfo.support")}</h3>
            <p>
                {t("header.contactInfo.friendlyTeam")} <br />
                {t("header.contactInfo.hereToHelp")}
            </p>
            <Link to={`mailto:${globalConfig.supportEmail}`}>
                {globalConfig.supportEmail}
            </Link>
            </div>
            <div className="col-12 lg:col-4 md:col-4">
            <h3>{t("header.contactInfo.sales")}</h3>
            <p>
                {t("header.contactInfo.friendlyTeam")} <br />
                {t("header.contactInfo.hereToHelp")}
            </p>
            <Link to={`mailto:${globalConfig.salesEmail}`}>
                {globalConfig.salesEmail}
            </Link>
            </div>
            <div className="col-12 lg:col-4 md:col-4">
            <h3>{t("header.contactInfo.phone")}</h3>
            <p>
            {t("header.contactInfo.mon")}-{t("header.contactInfo.fri")} {t("header.contactInfo.from")} 8am {t("header.contactInfo.to")} <br />
                5pm.
            </p>
            <span><Link to={`tel:${globalConfig.phoneNumber}`}>{globalConfig.phoneNumber}</Link> </span>
            </div>
        </div>
    </div>
    </section>
  );
};

export default SupportMailsSection;
